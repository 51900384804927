<template>
  <div>
    <div class="bg-theme">
      <CommonHeader />
      <div>
        <v-row>
          <v-col cols="12" lg="6" class="d-flex justify-center align-center">
            <div class="Holder">
              <h1 class="aboutSectionHeading">
                Latest News & Learning Resources
              </h1>
              <v-divider class="dividerNewsCard mt-2 mb-4"></v-divider>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="position-relative">
              <v-img
                v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 500,
                  },
                }"
                max-height="400px"
                :src="blog"
              />
              <v-img
                max-width="400px"
                min-width="399px"
                class="floating-img"
                :src="blue"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div style="padding: 5%" class="pt-16 bg-white-theme">
      <div class="d-flex justify-center align-center flex-column mt-16">
        <v-divider class="dividerNewsCard mb-4"></v-divider>
        <h1 class="aboutPlatforms mb-8">Latest Blogs</h1>
      </div>
      <div style="margin: 20px 10%">
        <v-row>
          <v-col
            v-for="(card, index) in blogCards"
            :key="index"
            cols="12"
            lg="6"
            @click="handleSlideClick(card.id)"
          >
            <div class="blogCard d-flex flex-column">
              <v-row>
                <v-col cols="12" xl="5" lg="5" md="5" sm="12">
                  <div class="image-container">
                    <v-img :src="card.image" />
                  </div>
                </v-col>
                <v-col cols="12" xl="7" lg="7" md="7" sm="12">
                  <div class="text-container">
                    <p class="newsCardHeading">{{ card.heading }}</p>
                    <p class="newsCardText">{{ card.text }}</p>
                  </div>
                </v-col>
              </v-row>
              <div class="d-flex flex-column align-start mt-auto">
                <p class="postedOn mt-3">Posted on</p>
                <p class="newsCardDate">{{ card.date }}</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHeader from "../components/CommonNavbar";
import blog from "../assets/images/blog-two.png";
import box from "../assets/images/box.png";
import blue from "../assets/images/blue.png";
import aboutMain from "../assets/images/start-up.png";

export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      blog: blog,
      blue: blue,
      box: box,
      aboutMain: aboutMain,
      blogCards: [
        {
          id: 1,
          heading: "What’s growth hacking? 8 great books to learn more about it",
          text: "How you can set personal boundaries to reduce stress and burnout",
          date: "October 26, 2021",
          image: require("@/assets/images/1.png"),
        },
        {
          id: 2,
          heading: "3 experiments to improve your mobile funnel and reach your",
          text: "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
          date: "November 12, 2021",
          image: require("@/assets/images/2.png"),
        },
        {
          id: 3,
          heading: "What’s growth hacking? 8 great books to learn more about it",
          text: "How you can set personal boundaries to reduce stress and burnout",
          date: "October 26, 2021",
          image: require("@/assets/images/3.png"),
        },
        {
          id: 4,
          heading: "3 experiments to improve your mobile funnel and reach your",
          text: "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
          date: "November 12, 2021",
          image: require("@/assets/images/4.png"),
        },
        {
          id: 5,
          heading: "What’s growth hacking? 8 great books to learn more about it",
          text: "How you can set personal boundaries to reduce stress and burnout",
          date: "October 26, 2021",
          image: require("@/assets/images/5.png"),
        },
        {
          id: 6,
          heading: "3 experiments to improve your mobile funnel and reach your",
          text: "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
          date: "November 12, 2021",
          image: require("@/assets/images/1.png"),
        },
      ],
    };
  },
  methods: {
    handleSlideClick(postId) {
      this.$router.push({ name: "SingleBlog", params: { id: postId } });
    },
  },
};
</script>

<style scoped>
.postedOn {
  color: #adabb7;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.newsCardDate {
  color: #002a52;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.newsCardText {
  color: #adabb7;
  text-align: start;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.newsCardHeading {
  color: #20184e;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  text-align: start;
  font-weight: 700;
  line-height: 120%;
}
.bg-white-theme {
  background: #f2f4f6;
}
.aboutPlatforms {
  color: #002a52;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 700px;
  font-weight: 700;
  line-height: 50px;
}
.aboutPlatformsText {
  color: #002a52;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.5;
}
.aboutSectionHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 500px;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.aboutSectionParagraph {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  max-width: 570px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  opacity: 0.7;
}
.bg-theme {
  background-color: #002a52;
  background-image: url(../assets//images/bg-circle.png);
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-width: 44px;
  min-height: 9px;
}
.floating-img {
  position: absolute;
  bottom: -100px;
  right: 10%;
}
.blogCard {
  border-radius: 20px;
  background: #fff;
  width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.image-container {
  height: 200px; /* Set a fixed height for the image container */
}
.text-container {
  flex: 1;
}
.Holder {
  padding: 0 2%;
}
@media screen and (max-width: 470px) {
  .aboutSectionHeading {
    font-size: 35px;
    text-align: center;
  }
  .dividerNewsCard {
    margin: auto;
  }
}
</style>