<template>
  <div>
    <ProductsTransplantHeader />
    <ProductsProcess/>
    <!-- <ProductContact/> -->
    <NewsCom/>

    <FooterCom/>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import FooterCom from "@/components/FooterCom";
import ProductsTransplantHeader from "@/components/ProductsTransplantHeader.vue";
import ProductsProcess from "@/components/ProductsProcess.vue";
// import ProductContact from "@/components/ProductContact.vue"
import NewsCom from "@/components/NewsCom";


export default defineComponent({
  name: "HomeView",

  components: {
        ProductsTransplantHeader,
        ProductsProcess,
        // ProductContact,
        NewsCom,

        FooterCom

  },
});
</script>
