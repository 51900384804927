<template>
  <div>
    <div class="bg-theme">
      <CommonHeader />
      <div class="position-relative homeHeaderHodler">
        <h1 class="headerTxtMain mt-7 pt-16">Maximize your Medicare/Medicaid ROI  </h1>
        <h1 class="headerTxtMain pb-3">With a Powerful & Affordable Platform</h1>
        <p class="headerParagraph pb-8">
Transform each minute of your work into higher reimbursement and better productivity         </p>
        <div class="d-flex justify-center my-8 mb-6">
          <router-link to="/contact">
            <v-btn class="getStartedBtn">Get Started</v-btn>
          </router-link>
        </div>
        <div
          v-motion
          :initial="{
            scale: 0.8,
          }"
          :visible="{
            scale: 1,
            transition: {
              duration: 500,
            },
          }"
        >
          <v-img class="ipadImage" :src="ipad" />
        </div>
      </div>
    </div>
    <div class="bg-white-theme py-16">
      <div class="contnet" >
       <v-divider class="dividerCard1"></v-divider>
    
      <h1 class="contneth1">Carefully Designed and Built <br>for Healthcare Provider</h1>
      <p class="contnetp pt-5 pb-10">Easy and Scalable Solution with No Bull Pricing</p>
      </div>
      <v-row class="w-100" style="padding: 0px 8%">
        <v-col cols="12" lg="3" md="6" sm="12" xs="12">
          <div class="d-flex justify-center align-center">
            <v-card
               min-width="240px"
              min-height="351px"
              class="homeCard pa-10"
              
              @mouseenter="showDivider($event)"
              @mouseleave="hideDivider($event)"
            >
              <v-img max-width="84px" class="mx-auto mt-8 mb-3" :src="icon1" />
              <p class="cardHead">Superior Returns</p>
              <p class="cardTxt mb-8">
               With MTS, its a gift that keeps on giving. 
               Get up to 15x return on your investment within a quarter.
              </p>
              <v-divider class="dividerCard"></v-divider>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" xs="12">
          <div class="d-flex justify-center align-center">
            <v-card
               min-width="240px"
              min-height="341px"
              class="homeCard pa-10"
              @mouseenter="showDivider($event)"
              @mouseleave="hideDivider($event)"
            >
              <v-img max-width="84px" class="mx-auto mt-8 mb-3" :src="icon2" />
              <p class="cardHead"> Better Engagement</p>
              <p class="cardTxt mb-8">
             Our user-friendly web and mobile 
             interfaces make time collection and reporting a breeze.
              </p>
              <v-divider class="dividerCard"></v-divider>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" xs="12">
          <div class="d-flex justify-center align-center">
            <v-card
             min-width="240px"
              min-height="341px"
              class="homeCard pa-10"
              @mouseenter="showDivider($event)"
              @mouseleave="hideDivider($event)"
            >
              <v-img max-width="84px" class="mx-auto mt-8 mb-3" :src="icon3" />
              <p class="cardHead"> A.I. Powered</p>
              <p class="cardTxt mb-8">
              We put intelligence ahead in every 
              feature of our platform. Manage your time smartly with MTS.
              </p>
              <v-divider class="dividerCard"></v-divider>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" xs="12">
          <div class="d-flex justify-center align-center">
            <v-card
              min-width="240px"
              min-height="341px"
              class="homeCard pa-10"
              @mouseenter="showDivider($event)"
              @mouseleave="hideDivider($event)"
            >
              <v-img max-width="84px" class="mx-auto mt-8 mb-3" :src="icon4" />
              <p class="cardHead">Powerful Analytics</p>
              <p class="cardTxt mb-8"> 
               Get real time analytics, workflow management and other 
               reporting tools to get detail insight into your operations.
              </p>
              <v-divider class="dividerCard"></v-divider>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import CommonHeader from "../components/CommonNavbar.vue";
import ipad from "../assets/images/iPad-Mockup.png";
import icon1 from "../assets/images/icon1.png";
import icon2 from "../assets/images/icon2.png";
import icon3 from "../assets/images/icon3.png";
import icon4 from "../assets/images/icon4.png";
export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      ipad: ipad,
      icon1: icon1,
      icon2: icon2,
      icon3: icon3,
      icon4: icon4,
    };
  },
  methods: {
    showDivider(event) {
      const card = event.currentTarget;
      card.querySelector(".dividerCard").style.display = "block";
    },
    hideDivider(event) {
      const card = event.currentTarget;
      card.querySelector(".dividerCard").style.display = "none";
    },
  },
};
</script>
<style scoped>
.contnet{
 align-items: center;
    display: flex !important;
    justify-content: center !important;flex-direction:column
}

.contneth1{
  color: #20184E;
  text-align: center;
  font-family: Nunito;
  font-size: 46px;
  font-style: normal;
  font-weight: 800;
}
.contnetp{
  color: #ADABB7;
  text-align: center;
  font-family: Nunito;
  font-size:18px;
  font-style: normal;
  font-weight: 800;
}
.dividerCard1 {

     border-radius: 57px;
    border: 0;
    background: #18afba; 
    opacity: 1;
    width: 54px;
    min-height: 9px;
    margin: 34px 0px;
}
.homeHeaderHodler {
  max-height: 650px;
}
.bg-theme {
  background-color: #002a52;
}
.dividerCard {
  border-radius: 57px;
  background: #002a52;
  display: none;
  opacity: 1;
  max-width: 44px;
  min-width: 44px;
  min-height: 9px;
  margin: 0 auto;
  transition: display 1s ease;
  position: absolute;
  bottom: 0;
  left: 42%;
}
.ipadImage {
}
.headerTxtMain {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 50px;
  font-style: normal;
  max-width: 1200px;
  font-weight: 700;
  margin: auto;
  line-height: 110%; /* 66px */
  text-transform: capitalize;
}
.bg-white-theme {
  background: #f2f4f6;
  padding-top: 25% !important;
}
.homeCard {
  border-radius: 20px;
  background: #fff;
  position: relative;
  box-shadow: none;
}
.homeCard:hover {
  box-shadow: 1px 4px 43px -19px black;
}
.headerParagraph {
  color: #ccc;
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
}
.getStartedBtn {
  border-radius: 40px;
  background: #18afba;
  text-transform: capitalize;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  min-height: 57px;
  font-size: 18px;
  font-style: normal;
  box-shadow: none;
  font-weight: 500;
  width: 164px;
  line-height: normal;
}
.tryFreeTrialBtn {
  color: #fff;
  min-height: 57px;
  width: 164px;
  text-transform: capitalize;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  border-radius: 40px;
  background: rgba(24, 175, 186, 0.2);
  font-weight: 500;
  box-shadow: none;
  line-height: normal;
}
.cardHead {
  color: #20184e;
  text-align: center;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
}
.cardTxt {
  color: #adabb7;
  padding-top: 10px;
  text-align: center;
  margin: auto;
  font-family: Nunito;
  font-size: 17px;
  max-width: 200px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
}
/* For medium screens (MD) */
@media screen and (min-width: 768px) and (max-width: 991px) {
  /* Your CSS styles for MD screens go here */
  .headerTxtMain {
    font-size: 45px;
    max-width: 700px;
  }
  .headerParagraph {
    font-size: 16px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .headerTxtMain {
    font-size: 27px;
    max-width: 700px;
  }
  .headerParagraph {
    font-size: 16px;
  }
}
@media screen and (max-width: 470px) {
  .headerTxtMain {
    font-size: 35px;
  }
  .contneth1 {
    font-size: 27px!important
    }
    .contnetp {
    font-size: 13px!important;}
}
</style>
<style>
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .floating-img {
    min-width: 250px !important;
    z-index: 999;
    right: 0% !important;
  }
    .contneth1 {
    font-size: 20px!important
    }
    .contnetp {
    font-size: 13px!important;}
}
</style>
