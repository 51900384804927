<template>
  <div>
    <div class="pt-16 feature-bg">
      <v-divider class="dividerNewsCard mt-16 mb-4"></v-divider>
      <h1 class="teamHeading">Our Features</h1>
      <p class="featuresPara mb-16 mt-2">
        Purpose-built platform with the functionality you expect and more.
      </p>
    </div>
    <div style="padding: 0 10%;">
      <div>
        <Carousel
          class="reviewCarosel"
          :items-to-show="3"
          :breakpoints="carouselBreakpoints"
          :wrap-around="true"
        >
          <Slide v-for="(slide, index) in carouselSlides" :key="index">
            <!-- <v-card class="organization-card"> -->
            <div class="carousel__item pa-5">
              <h2 class="orgCardHeading">{{ slide.heading }}</h2>
              <v-divider class="dividerNewsCard smallDivider ma-0 mt-3 mb-4"></v-divider>
              <p class="orgCardText ms-5">{{ slide.text }}</p>
            </div>
            <!-- </v-card> -->
          </Slide>
          <template #addons>
            <Pagination class="mt-16" />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
export default defineComponent({
  name: "WrapAround",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      carouselBreakpoints: {
        1260: { itemsToShow: 3 },
        1060: { itemsToShow: 2 },
        860: { itemsToShow: 2 },
        600: { itemsToShow: 1 },
        0: { itemsToShow: 1 },
      },
      carouselSlides: [
        {
          heading: "Business Rules Management System",
          text: "Automates configurable and consistent data gathering so it will work how you need it to.",
        },
        {
          heading: "No More Burdensome Spreadsheets",
          text: "Because studies are tracked and reported automatically and in the format you need.",
        },
        {
          heading: "Mobile App",
          text: "Increases compliance to greater than 95%.",
        },
        {
          heading: "No More Nagging!",
          text: "Automated reminders keep practitioners on task so they enter data in a timely manner.",
        },
        {
          heading: "Audit Logs",
          text: "Record in detail when data was entered and tracks attestations.",
        },
        {
          heading: "Flexible Time Study Programs",
          text: "To meet any use case.",
        },
        {
          heading: "Real-Time Validation",
          text: "Helps practitioners avoid mistakes by checking it as it is entered.",
        },
        {
          heading: "Advanced Analytics",
          text: "Tools provide powerful insights.",
        },
      ],
    };
  },
});
</script>
<style scoped>
.smallDivider{
  min-width: 29px !important;
    min-height: 6px !important;
}
.feature-bg{
  background-image: url(../assets/images/half.png);
  background-position: right;
  background-position: right top;
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  margin: auto;
  min-width: 44px;
  min-height: 9px;
}
.teamHeading {
  color: #002a52;
  text-align: center;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}
.featuresPara {
  color: #002a52;
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.carousel__item {
  border-radius: 20px;
  background: #002a52;
  color: white;
  /* min-width: 345px; */
  max-width: 346px;
  min-height: 218px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.orgCardHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 24px;
  text-align: start;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
}
.orgCardText {
  color: #adabb7;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  text-align: start;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
}
@media (max-width: 991px) {
  /* Your CSS styles for MD screens go here */
  .teamHeading {
    font-size: 38px;
    max-width: 619px;
  }
  .featuresPara {
    font-size: 18px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .teamHeading {
    font-size: 38px;
    max-width: 619px;
  }
}
</style>
