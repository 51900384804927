<template>
  <div class="mt-16">
    <div>
      <v-img
        v-motion
        :initial="{
          y: 100,
        }"
        :visible="{
          y: 0,

          transition: {
            duration: 500,
          },
        }"
        class="smartIphone floating-img"
        :src="smart"
      />
    </div>
    <div class="bg-theme min-height-600">
      <v-divider class="dividerNewsCard mt-16 mb-4"></v-divider>
      <h2 class="featureMainText">
        If your healthcare system doesn’t use time:study NOW yet, you can sign
        up for individual use through our lite plan for FREE.
      </h2>
      <div class="d-flex justify-center">
        <router-link to="/contact">
          <v-btn class="signUp mt-5 mb-16"> Schedule A Demo </v-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import smart from "../assets/images/smartphones.svg";
export default {
  data() {
    return {
      smart: smart,
    };
  },
};
</script>
<style scoped>
.smartIphone {
  margin-bottom: -20% !important;
  /* margin-bottom: -50% !important; */
  width: 80vw;
  margin: auto;
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  margin: auto;
  min-width: 44px;
  min-height: 9px;
}
.min-height-600 {
  min-height: 600px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;
}
.signUp {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 40px;
  background: #18afba;
  text-transform: capitalize;
  box-shadow: none;
  height: 57px;
  margin: auto;
  width: 209px;
}
.featureMainText {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  max-width: 900px;
  font-size: 30px;
  /* margin: auto; */
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.bg-theme {
  background-color: #002a52;
}
@media (max-width: 991px) {
  /* Your CSS styles for MD screens go here */

  .featureMainText {
    font-size: 25px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
}
</style>
