<template>
<div>
    <ProductsHeader />
    <ProductsFeatures/>
    <ProductsApp/>
    <NewsCom/>

    <FooterCom />
</div>
</template>

  
<script>
import {
    defineComponent
} from "vue";
import ProductsHeader from "@/components/ProductsHeader.vue";
import ProductsFeatures from "@/components/ProductsFeatures.vue";
import  ProductsApp from "@/components/ProductsApp.vue";
import NewsCom from "@/components/NewsCom";
import FooterCom from "@/components/FooterCom";

export default defineComponent({
    name: "AboutView",

    components: {
        ProductsHeader,
        ProductsFeatures,
        FooterCom,
        ProductsApp,
        NewsCom 
    },
});
</script>
