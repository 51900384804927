<template>
  <div>
    <div class="bg-theme">
      <CommonHeader />
      <div>
        <v-row>
          <v-col cols="12" lg="6" class="d-flex justify-center align-center">
            <div class="Holder">
              <h1 class="aboutSectionHeading">Schedule A Demo</h1>
              <v-divider class="dividerNewsCard mt-2 mb-4"></v-divider>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="position-relative">
              <v-img
                v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 500,
                  },
                }"
                :src="about"
                max-height="495"
              />
              <v-img
                max-width="400px"
                min-width="399px"
                class="floating-img"
                :src="blue"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="bg-theme-white" style="padding: 10% 0 5%">
      <div style="width: 80vw; margin: auto">
        <v-card class="contactCard py-16 px-8">
          <div class="d-flex justify-center align-center flex-column">
            <v-divider class="dividerNewsCard mb-4"></v-divider>
            <h1 class="aboutPlatforms mb-10">
              Let's discuss how Time Study’s platform can help your organization
            </h1>
            <div class="w-100 mt-5">
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      class="fieldTheme"
                      placeholder="First name*"
                      :rules="firstNameRules"
                      variant="solo-filled"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      class="fieldTheme"
                      placeholder="Last name*"
                      :rules="lastNameRules"
                      variant="solo-filled"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      class="fieldTheme"
                      placeholder="Company name*"
                      :rules="companyNameRules"
                      variant="solo-filled"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      class="fieldTheme"
                      placeholder="Job title"
                      :rules="jobTitleRules"
                      variant="solo-filled"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      class="fieldTheme"
                      placeholder="Email*"
                      :rules="emailRules"
                      variant="solo-filled"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      class="fieldTheme"
                      placeholder="Phone*"
                      :rules="phoneRules"
                      variant="solo-filled"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" lg="12">
                    <v-textarea
                      class="fieldTheme"
                      placeholder="Message"
                      :rules="descriptionRules"
                      variant="solo-filled"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
              <div>
                <v-btn @click="submitForm()" class="SubmitBtn mt-5"
                  >Submit</v-btn
                >
              </div>
            </div>
          </div>
        </v-card>
        <div class="my-16">
          <v-row>
            <v-col cols="12" lg="4">
              <div class="d-flex justify-center">
                <v-img class="me-3" max-width="24px" :src="email" />
                <span class="contactSpan">user12345@gmail.com</span>
              </div>
            </v-col>
            <v-col cols="12" lg="4">
              <div class="d-flex justify-center">
                <v-img class="me-3" max-width="24px" :src="phone" />
                <span class="contactSpan">+123 456 789</span>
              </div>
            </v-col>
            <v-col cols="12" lg="4">
              <div class="d-flex justify-center">
                <v-img class="me-3" max-width="24px" :src="location" />
                <span class="contactSpan">123 Street 456 House</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonHeader from "../components/CommonNavbar";
import about from "../assets/images/contact-img-two.png";
import blue from "../assets/images/blue.png";
import aboutMain from "../assets/images/start-up.png";
import email from "../assets/images/email.svg";
import phone from "../assets/images/phone.svg";
import location from "../assets/images/location.svg";
export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      about: about,
      blue: blue,
      email: email,
      phone: phone,
      location: location,
      aboutMain: aboutMain,
      firstNameRules: [
        (v) => (v && v.trim() !== "") || "First Name is required", // Check if the input is not undefined and not empty or contains only whitespace
        (v) => /^[a-zA-Z\s]*$/.test(v) || "Only alphabets are allowed", // Allow alphabets and spaces
        (v) => v.length <= 25 || "Name must not exceed 25 characters", // Ensure the length is not more than 25 characters
      ],
      lastNameRules: [
        (v) => (v && v.trim() !== "") || "Last Name is required", // Check if the input is not undefined and not empty or contains only whitespace
        (v) => /^[a-zA-Z\s]*$/.test(v) || "Only alphabets are allowed", // Allow alphabets and spaces
        (v) => v.length <= 25 || "Name must not exceed 25 characters", // Ensure the length is not more than 25 characters
      ],
      companyNameRules: [
        (v) => (v && v.trim() !== "") || "Company Name is required",
        (v) =>
          /^[a-zA-Z0-9\s]*$/.test(v) ||
          "Only alphabets, numbers, and spaces are allowed",
        (v) => v.length <= 25 || "Company Name must not exceed 25 characters",
      ],

      jobTitleRules: [
        (v) => (v && v.trim() !== "") || "Job Title is required", // Check if the input is not undefined and not empty or contains only whitespace
        // (v) => /^[a-zA-Z\s]*$/.test(v) || "Only alphabets are allowed", // Allow alphabets and spaces
        (v) => v.length <= 25 || "Name must not exceed 25 characters", // Ensure the length is not more than 25 characters
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => /^\d{10}$/.test(v) || "Phone must be a 10-digit number",
      ],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ],
      descriptionRules: [
        (v) => !!v || "Description is required",
        (v) => v.length <= 250 || "Name must not exceed 250 characters",
      ],
    };
  },
  methods: {
    async submitForm() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
    },
  },
};
</script>
<style>
.fieldTheme .v-field {
  box-shadow: none !important;
  background: #ebf2f4;
}
</style>
<style scoped>
.contactSpan {
  color: #002a52;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.contactCard {
  border-radius: 30px;
  background: #fff;
  box-shadow: none;
}
.bg-theme-white {
  background: #f2f4f6;
}
.aboutPlatforms {
  color: #002a52;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 880px;
  font-weight: 700;
  text-align: center;
  line-height: 50px;
}
.SubmitBtn {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 50px;
  background: #18afba;
  min-height: 65px;
  min-width: 173px;
  text-transform: capitalize;
  box-shadow: none;
}
.aboutPlatformsText {
  color: #002a52;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.5;
}
.aboutSectionHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.aboutSectionParagraph {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  max-width: 570px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  opacity: 0.7;
}
.bg-theme {
  background-color: #002a52;
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-width: 44px;
  min-height: 9px;
}
.floating-img {
  position: absolute;
  bottom: -100px;
  right: 10%;
}
@media (max-width: 991px) {
  /* Your CSS styles for MD screens go here */
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
  .aboutPlatformsText {
    font-size: 18px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .aboutPlatforms {
    font-size: 31px;
    max-width: 619px;
    line-height: 40px;
  }
}
.Holder {
  padding: 0 2%;
}
@media screen and (max-width: 470px) {
  .aboutSectionHeading {
    font-size: 35px;
    text-align: center;
  }
  .dividerNewsCard {
    margin: auto;
  }
}
</style>
