<template>
  <div class="bg-theme-white pt-16">
    <div class="newsWrapper">
      <v-row class="w-100">
        <v-col cols="12" lg="9" md="12">
          <div>
            <v-divider class="dividerNewsCard mb-4"></v-divider>
            <p class="newsHeading">Latest News & Learning Resources</p>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="12">
          <div class="d-flex justify-center align-center h-100">
            <v-btn class="articleBtn">See More Articles</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row class="w-100" style="padding: 0 5%">
      <v-col cols="12">
        <div>
          <Carousel
            class="reviewCarosel"
            :breakpoints="carouselBreakpoints"
            :wrap-around="true"
            :autoplay="2000"
          >
            <Slide
              v-for="(slide, index) in blogsArray"
              :key="index"
              @click="handleSlideClick(slide.id)"
            >
              <div class="carousel__item">
                <div>
                  <v-row>
                    <v-col cols="12" lg="5" md="5" sm="12">
                      <div class="h-100">
                        <v-img class="blogNews" :src="slide.image" />
                      </div>
                    </v-col>
                    <v-col cols="12" lg="7" md="7" sm="12">
                      <div>
                        <p class="newsCardHeading">{{ slide.title }}</p>
                        <p class="newsCardText">{{ slide.body }}</p>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="d-flex flex-column align-start">
                    <p class="postedOn">Posted on</p>
                    <p class="newsCardDate">{{ slide.created_at }}</p>
                  </div>
                </div>
              </div>
            </Slide>

            <template #addons>
              <Pagination class="mt-5" />
            </template>
          </Carousel>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";
import box from "../assets/images/box.png";

export default defineComponent({
  name: "WrapAround",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      blogsArray: [
        {
          id: 1,
          title: "What’s growth hacking? 8 great books to learn more about it",
          body: "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
          image: require("@/assets/images/1.png"),
          created_at: "October 26, 2021",
        },
        {
          id: 2,
          title: "3 experiments to improve your mobile funnel and reach your",
          body: "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
          image: require("@/assets/images/2.png"),
          created_at: "October 26, 2021",
        },
        {
          id: 3,
          title: "6 new interesting mobile apps to grow your audience on",
          body: "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
          image: require("@/assets/images/3.png"),
          created_at: "October 26, 2021",
        },
        {
          id: 4,
          title: "What’s growth hacking? 8 great books to learn more about it",
          body: "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
          image: require("@/assets/images/4.png"),
          created_at: "October 26, 2021",
        },
        {
          id: 5,
          title: "3 experiments to improve your mobile funnel and reach your",
          body: "Lorem ipsum dolor sit amet consectetur adipiscing elit interdum",
          image: require("@/assets/images/5.png"),
          created_at: "October 26, 2021",
        },
      ],
      box: box,
      imgurl: null,
      carouselBreakpoints: {
        1260: { itemsToShow: 3 },
        1060: { itemsToShow: 2 },
        860: { itemsToShow: 2 },
        600: { itemsToShow: 1 },
        0: { itemsToShow: 1 },
      },
    };
  },
  methods: {
    handleSlideClick(postId) {
      this.$router.push({ name: "SingleBlog", params: { id: postId } });
    },
  },
});
</script>

<style>
.reviewCarosel {
  position: relative;
}
.reviewCarosel .carousel__next {
  position: absolute;
  top: 100%;
  left: 33px;
  margin-left: 30px;
  color: white;
  background: #18afba !important;
  border-radius: 100%;
}
.reviewCarosel .carousel__prev {
  position: absolute;
  top: 100%;
  left: 0px;
  color: white;
  background: #18afba;
  border-radius: 100%;
}
</style>
<style scoped>
.reviewCarosel {
  min-height: 310px;
}
.newsCardHeading {
  color: #20184e;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  text-align: start;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
}
.newsCardText {
  color: #adabb7;
  text-align: start;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 23.4px */
}
.postedOn {
  color: #adabb7;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
}
.newsCardDate {
  color: #002a52;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.newsWrapper {
  padding: 0 5%;
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-height: 9px;
}
.newsHeading {
  color: #20184e;
  font-family: Nunito;
  font-size: 48px;
  max-width: 600px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: capitalize;
}
.articleBtn {
  border-radius: 40px;
  background: #18afba;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-height: 55px;
  box-shadow: none;
  text-transform: capitalize;
}
.carousel__item {
  border-radius: 20px;
  background: #fff;
  width: 90%;
  padding: 20px;
}
.blogNews {
  border-radius: 10px;
}
.bg-theme-white {
  background: #f2f4f6;
  padding-bottom: 5%;
}
@media (max-width: 991px) {
  /* Your CSS styles for MD screens go here */
  .newsHeading {
    font-size: 38px;
    max-width: 619px;
  }
  .newsCardHeading {
    font-size: 20px;
  }
  .newsCardText {
    font-size: 16px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .newsHeading {
    font-size: 38px;
    max-width: 619px;
  }
}
@media screen and (max-width: 470px) {
  .newsHeading {
    font-size: 35px;
  }
}
</style>
<style>
/* .carousel__pagination-button::after{
  background-color: white !important;
} */
 
.carousel__pagination-button:hover::after, .carousel__pagination-button--active::after{
  background-color: #17A7B3 !important;

}
</style>