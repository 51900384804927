<template>
  <div>
    <div class="bg-theme">
      <CommonHeader />
      <div>
        <v-row>
          <v-col cols="12" lg="6" class="d-flex justify-center align-center">
            <div class="Holder">
              <h1 class="aboutSectionHeading">
                The Most Friendly Time Study Mobile App.
              </h1>
              <v-divider class="dividerNewsCard mb-4"></v-divider>
              <div>
                <p class="aboutSectionParagraph ps-16">
                  Finally, Time Study Designed for Mobdile Devices
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="position-relative">
              <v-img
                v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 500,
                  },
                }"
                max-height="550px"
                min-height="400px"
                :src="about"
              />
              <v-img
                max-width="400px"
                min-width="399px"
                class="floating-img"
                :src="blue"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div style="padding: 2% 5%" class="pt-16 bg-white-theme">
      <v-divider class="dividerNewsCard mb-4"></v-divider>
      <h1 class="aboutPlatforms">The Time:Sheet NOW edge</h1>
      <p class="mb-5 mt-3 aboutPlatformsText">
        The time:study NOW product is used by physicians as part of enterprise
        plans as well as independently if it’s before their healthcare
        organization has rolled out the Time Study platform. The time:study NOW
        mobile app makes it quick and easy for physicians and practitioners to
        collect data about their most important activities to their
        organizations and themselves.
      </p>
    </div>
  </div>
</template>
<script>
import CommonHeader from "../components/CommonNavbar";
import about from "../assets/images/woman-doc.png";
import blue from "../assets/images/blue.png";
export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      about: about,
      blue: blue,
    };
  },
};
</script>
<style scoped>
.bg-white-theme {
  background: #f2f4f6;
}
.aboutPlatforms {
  color: #002a52;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 700px;
  font-weight: 700;
  line-height: 50px;
}
.aboutPlatformsText {
  color: #002a52;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.5;
}
.aboutSectionHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 600px;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.aboutSectionParagraph {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  max-width: 570px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  opacity: 0.7;
}
.bg-theme {
  background-color: #002a52;
  background-image: url(../assets//images/bg-circle.png);
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-height: 9px;
}
.floating-img {
  position: absolute;
  bottom: -100px;
  right: 10%;
}
@media (max-width: 991px) {
  /* Your CSS styles for MD screens go here */
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
  .aboutPlatformsText {
    font-size: 18px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
}
@media screen and (max-width: 470px) {
  .aboutSectionHeading {
    font-size: 35px;
    text-align: center;
  }
  .Holder {
    padding: 0 2%;
  }
  .aboutSectionParagraph {
    text-align: center;
    padding-left: 0 !important;
  }
  .dividerNewsCard {
    margin: auto;
  }
}
</style>
