<template>
  <div>
    <div class="bg-theme">
      <CommonHeader />
      <div>
        <v-row>
          <v-col cols="12" lg="6" class="d-flex justify-center align-center">
            <div class="Holder">
              <h1 class="aboutSectionHeading">The Leader In Time Studies</h1>
              <v-divider class="dividerNewsCard mt-2 mb-4"></v-divider>
              <div>
                <p class="aboutSectionParagraph ps-16">
                  Our enterprise-class system helps hospitals achieve remarkable
                  improvements in productivity, financial performance and
                  compliance.
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="position-relative">
              <v-img
               
                v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 500,
                  },
                }"
                :src="about"
                max-height="530"
              />
              <v-img
                max-width="500px"
                min-width="450px"
                class="floating-img"
                :src="blue"
                
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div style="padding: 7% 5%" class="bg-white-theme">
      <v-divider class="dividerNewsCard mb-4"></v-divider>
      <h1 class="aboutPlatforms">
        TimeSheet measures your most precious resource: Time
      </h1>
      <p class="mb-5 mt-3 aboutPlatformsText">
        Time Study is a SaaS platform built from the ground up to administer
        time studies for hospitals. The enterprise-class system ties into
        existing systems and delivers improvements in productivity, financial
        performance, and compliance.
      </p>
      <ul class="ml-8">
        <li class="aboutPlatformsText">
          Business Rules Management System (BRMS) – Our ‘brain’ where rules and
          data are mapped.
        </li>
        <li class="aboutPlatformsText">
          Data Collection – Captures responses for practitioners in a way that
          does not disrupt their work.
        </li>
        <li class="aboutPlatformsText">
          Integrations – The data pipeline passes information to other
          enterprise systems such as SSO and user profiles.
        </li>
        <li class="aboutPlatformsText">
          Analytics – Tells you what you want to know.
        </li>
        <li class="aboutPlatformsText">
          Insights – Advanced AI-based recommendations for best practices.
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import CommonHeader from "../components/CommonNavbar";
import about from "../assets/images/moc-2.png";
import blue from "../assets/images/blue.png";

export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      about: about,
      blue: blue,
    };
  },
};
</script>
<style scoped>
.bg-white-theme {
  background: #f2f4f6;
}
.aboutPlatforms {
  color: #002a52;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 700px;
  font-weight: 700;
  line-height: 50px;
}
.aboutPlatformsText {
  color: #002a52;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.5;
}
.aboutSectionHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 48px;
  max-width: 402px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.aboutSectionParagraph {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  max-width: 570px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  opacity: 0.7;
}
.bg-theme {
  background-color: #002a52;
  background-image: url(../assets//images/bg-circle.png);
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-height: 9px;
}
.floating-img {
  position: absolute;
  bottom: -100px;
  right: 10%;
}
@media (max-width: 991px) {
  /* Your CSS styles for MD screens go here */
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
  .aboutPlatformsText {
    font-size: 18px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
}
.Holder {
  padding: 0 2%;
}
@media screen and (max-width: 470px) {
  .aboutSectionHeading {
    font-size: 35px;
    text-align: center;
  }

  .aboutSectionParagraph {
    text-align: center;
    padding-left: 0 !important;
  }
  .dividerNewsCard {
    margin: auto;
  }
}
</style>
