<template>
  <div>
    <v-card elevation="0" class="bg-transparent">
      <div>
        <v-row class="pa-8">
          <v-col cols="12" lg="4" md="12" sm="12" xs="12">
            <div class="d-flex justify-space-between align-center h-100">
              <div>
                <v-img max-width="320px" min-width="275px" :src="logo" />
              </div>
              <div class="d-md-none">
                <v-btn class="menuBtn" @click="toggleMenu"
                  ><v-img min-width="30px" :src="menu"
                /></v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="8" md="12" sm="12" xs="12">
            <div class="navbar-links" :class="{ 'show-menu': isMenuOpen }">
              <ul class="unorderList-navbar">
                <router-link class="routerLink" to="/">
                  <li
                    class="navbar-link my-3"
                    v-bind:class="{ 'navbar-link-active': isHome }"
                  >
                    Home
                  </li></router-link
                >

                <router-link class="routerLink" to="/about">
                  <li
                    class="navbar-link my-3"
                    v-bind:class="{ 'navbar-link-active': isAbout }"
                  >
                    About
                  </li></router-link
                >
                  <router-link
                        class="routerLink justify-end"
                        to="/products"
                      >
                        <v-list-item-title
                          class="navbar-link"
                          v-bind:class="{ 'navbar-link-active':  isProducts }"
                          >Products</v-list-item-title
                        >
                      </router-link>
                <router-link class="routerLink" to="/Features">
                  <li
                    class="navbar-link my-3"
                    v-bind:class="{ 'navbar-link-active': isFeatures }"
                  >
                    Features
                  </li></router-link
                >
                <!-- <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ props }">
                    <li
                      class="navbar-link my-3"
                      v-bind="props"
                      v-bind:class="{ 'navbar-link-active': areProductsActive }"
                    >
                      Products
                    </li>
                  </template> -->

                  <!-- <v-list class="productsList">
                    <v-list-item>
                      <router-link
                        class="routerLink justify-end"
                        to="/product-transplant"
                      >
                        <v-list-item-title
                          class="navbar-link"
                          v-bind:class="{ 'navbar-link-active': isTransplant }"
                          >Transplant Center</v-list-item-title
                        >
                      </router-link>
                      <v-divider class="listDivider"></v-divider>
                      <router-link
                        class="routerLink d-flex justify-end"
                        to="/product-Faculty"
                      >
                        <v-list-item-title
                          class="navbar-link"
                          v-bind:class="{ 'navbar-link-active': isFaculty }"
                          >Faculty</v-list-item-title
                        >
                      </router-link>
                    </v-list-item>
                  </v-list>
                </v-menu> -->

                <!-- <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ props }">
                    <li
                      class="navbar-link my-3"
                      v-bind="props"
                      v-bind:class="{ 'navbar-link-active': areFeaturesActive }"
                    >
                      Products
                    </li>
                  </template> -->

                  <!-- <v-list class="productsList">
                    <v-list-item>
                      <router-link
                        class="routerLink justify-end"
                        to="/product-transplant"
                      >
                        <v-list-item-title
                          class="navbar-link"
                          v-bind:class="{ 'navbar-link-active':  isTransplant }"
                          >Transplant center</v-list-item-title
                        >
                      </router-link>
                      <v-divider class="listDivider"></v-divider>
                      <router-link
                        class="routerLink d-flex justify-end"
                        to="/product-faculty"
                      >
                        <v-list-item-title
                          class="navbar-link"
                          v-bind:class="{ 'navbar-link-active': isFaculty }"
                          >Faculty</v-list-item-title
                        >
                      </router-link>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
<!-- 
                <router-link class="routerLink" to="/pricing">
                  <li
                    class="navbar-link my-3"
                    v-bind:class="{ 'navbar-link-active': isPricing }"
                  >
                    Pricing
                  </li></router-link
                > -->
                <!-- <router-link class="routerLink" to="/blogs">
                  <li
                    class="navbar-link my-3"
                    v-bind:class="{ 'navbar-link-active': isBlog }"
                  >
                    Blogs
                  </li></router-link
                > -->
                <router-link class="routerLink" to="/contact">
                  <li
                    class="navbar-link my-3"
                    v-bind:class="{ 'navbar-link-active': isContact }"
                  >
                    Contact
                  </li></router-link
                >

                <div class="navbar-login"></div>
                <a
                  class="routerLink"
                  href="https://dashboard.medicaretimestudy.com/"
                  target="_blank"
                >
                  <li class="navbar-link my-3">Client Login</li></a
                >
                <!-- <router-link class="routerLink" to="/login">
                  <li
                    class="navbar-link my-3"
                    v-bind:class="{ 'navbar-link-active': isLogin }"
                  >
                    Client Login
                  </li></router-link
                > -->
                <router-link class="routerLink" to="/">
                  <li class="navbar-link">
                    <router-link
                      class="routerLink text-decoration-none"
                      to="/contact"
                    >
                      <v-btn class="getStarted"
                        >Schedule A Demo</v-btn
                      ></router-link
                    >
                  </li></router-link
                >
              </ul>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import logo from "../assets/images/Medicare.png";
import menu from "../assets/images/List.png";

export default {
  data() {
    return {
      logo: logo,
      menu: menu,
      isMenuOpen: false,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  computed: {
    homeLink() {
      return this.$route.path === "/" ? "/" : "/home";
    },
    isHome() {
      return this.$route.path === "/";
    },
    isAbout() {
      return this.$route.path === "/about";
    },
    isPricing() {
      return this.$route.path === "/pricing";
    },
    isBlog() {
      return this.$route.path === "/blogs";
    },
    isContact() {
      return this.$route.path === "/contact";
    },

    isMobile() {
      return this.$route.path === "/product-mobile";
    },
    isTransplant() {
      return this.$route.path === "/product-transplant";
    },
    isFaculty() {
      return this.$route.path === "/product-faculty";
    },

    isWeb() {
      return this.$route.path === "/product-web";
    },
    areFeaturesActive() {
      return this.isWeb || this.isMobile;
    },
    areProductsActive() {
      return this.isTransplant || this.isFaculty;
    },

    isLogin() {
      return this.$route.path === "/login";
    },
    isProducts() {
      return this.$route.path === "/products";
    },
    isFeatures(){
      return this.$route.path === "/Features";

    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap");
</style>

<style scoped>
.listDivider {
  border-radius: 10px;
  opacity: 0.2;
  background: #fff;
  width: 198px;
  height: 1px;
  margin: 10px 0;
}
.productsList {
  border-radius: 10px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  width: 248px;
  box-shadow: none !important;
}
.getStarted {
  border-radius: 40px;
  background: #18afba;
  color: #fff;
  text-align: center;
  font-family: Gowun Dodum;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-height: 48px;
  width: 190px;
  box-shadow: none;
  text-transform: capitalize;
}
.unorderList-navbar {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.navbar-link {
  font-family: "Gowun Dodum", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  cursor: pointer;
  color: #fff;
}
.navbar-link-active {
  color: #18afba;
}
.routerLink {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.show-menu {
  display: none;
}
.navbar-login {
  border: 1px solid transparent; /* Set a transparent border to reserve space */
  border-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #ffffff 49%,
    rgba(0, 0, 0, 0) 100%
  );
  border-image-slice: 1; /* Ensure the border image covers the entire border */
  @media only screen and (max-width: 600px) {
    border: none; /* Hide the border on smaller screens */
  }
}
@media (max-width: 960px) {
  .navbar-links {
    display: none;
    flex-direction: column;
  }
  .unorderList-navbar {
    flex-direction: column;
    align-items: center;
  }

  .show-menu {
    display: block !important;
  }
}
.menuBtn {
  background-color: transparent;
  box-shadow: none;
}
</style>
<style>
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: #18afba;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
::-webkit-scrollbar-track:hover {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
</style>
