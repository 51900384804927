<template>
  <div>
    <PricingHeader />
    <PackageCom />
    <ReviewsCom />
    <FooterCom />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import PricingHeader from "@/components/PricingHeader";
import PackageCom from "@/components/PackageCom";
import ReviewsCom from "@/components/ReviewsCom";
import FooterCom from "@/components/FooterCom";

export default defineComponent({
  name: "PricingView",

  components: {
    PricingHeader,
    PackageCom,
    ReviewsCom,
    FooterCom,
  },
});
</script>
