<template>
  <div>
    <ProductWebHeader />
    <OurFeatures />
    <ProductWebMain />
    <ReviewsCom />
    <FooterCom />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ProductWebHeader from "@/components/ProductWebHeader";
import ProductWebMain from "@/components/ProductWebMain";
import ReviewsCom from "@/components/ReviewsCom";
import OurFeatures from "@/components/OurFeatures";
import FooterCom from "@/components/FooterCom";

export default defineComponent({
  name: "ProductWebView",

  components: {
    ProductWebHeader,
    ProductWebMain,
    ReviewsCom,
    OurFeatures,
    FooterCom,
  },
});
</script>
