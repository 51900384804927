<template>
  <div>
    <ContactHeader />
    <!-- <PackageCom /> -->
    <FooterCom />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ContactHeader from "@/components/ContactHeader";
// import PackageCom from "@/components/PackageCom";
import FooterCom from "@/components/FooterCom";

export default defineComponent({
  name: "PricingView",

  components: {
    ContactHeader,
    // PackageCom,
    FooterCom,
  },
});
</script>
