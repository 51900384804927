<template>
  <div class="bg-theme-white organization-org py-16">
    <div class="organizationTop">
      <v-row class="mt-8" style="width: 100%">
        <v-col cols="12" lg="9" md="12">
          <div>
            <v-divider class="dividerOrgCard mb-4"></v-divider>
            <p class="reviewHeading mb-8">
              Made for healthcare by healthcare professionals
            </p>
          </div>
        </v-col>
        <v-col cols="12" lg="3" md="12">
          <div class="d-flex justify-center align-center h-100">
            <router-link class="routerLink text-decoration-none" to="/contact">
              <v-btn class="demoBtn">Schedule a Demo</v-btn>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12" xs="12">
        <div class="ml-10 mr-10">
          <v-row class="d-flex flex-wrap align-stretch">
            <v-col cols="12" md="6" lg="4" v-for="(slide, index) in carouselSlides" :key="index">
              <div class="organization-card d-flex flex-column">
                <v-img
                  style="height: 75px; width: 72px; margin-top: 13px; margin-left: 16px !important; overflow: visible;"
                  :src="slide.Images"
                ></v-img>
                <h2 class="orgCardHeading">{{ slide.heading }}</h2>
                <p class="orgCardText">{{ slide.text }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import secure from "../assets/images/secure.png";
import idea from "../assets/images/idea.png";
import configure from "../assets/images/configure.png";
import importImg from "../assets/images/importImg.png";
import thirdParty from "../assets/images/thirdParty.png";
import search from "../assets/images/search.png";
import calculator from "../assets/images/calculator.png";
import noLimits from "../assets/images/noLimits.png";

export default defineComponent({
  name: "WrapAround",
  data() {
    return {
      carouselSlides: [
        {
          Images: configure,
          heading: "Fully Configurable",
          text: "Our solution is ready to meet your unique needs. Multi-layer, user-base access system offers maximum configuration capabilities.",
        },
        {
          Images: secure,
          heading: "Secure and Scalable",
          text: "We are HIPAA compliant and ensure that your data is secure at every stage.",
        },
        {
          Images: importImg,
          heading: "EZ Import",
          text: "You can upload your historical data with quick and easy steps without the need of any complex programming.",
        },
        {
          Images: thirdParty,
          heading: "Third-Party Integrations",
          text: "We are continuously expanding our ecosystem of third-party softwares.",
        },
        {
          Images: search,
          heading: "Powerful Analytics",
          text: "Our AI-charged analytics provide you real time data and reporting capabilities for better management.",
        },
        {
          Images: calculator,
          heading: "Mobile Apps",
          text: "A.I. powered IOS and Android apps let you manage your time and reminders on-the-go.",
        },
        {
          Images: noLimits,
          heading: "No Limits",
          text: "You can conduct unlimited time studies and have unlimited number of users without paying extra.",
        },
        {
          Images: idea,
          heading: "Comprehensive Solution",
          text: "MTS is the industry’s most comprehensive and powerful time study solution designed for medicare, medicaid and other needs of today’s healthcare industry.",
        },
      ],
    };
  },
});
</script>

<style scoped>
.reviewCarosel {
  min-height: 275px;
}
.orgCardText {
  color: #adabb7;
  text-align: left;
  padding: 0px 20px;
  padding-top: 10px;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  flex-grow: 1;
}
.orgCardHeading {
  color: #20184E;
  font-family: Nunito;
  padding-top: 20px;
  font-size: 21px;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  padding-left: 20px;
}
.organization-card {
  border-radius: 16px;
  background: #fff;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 48px 37px 104px 0px #140b4817;

}
.organization-org {
  background-image: url(../assets/images/half.png);
  background-position: right;
  padding-bottom: 5%;
  background-position: right top;
}
.organizationTop {
  padding: 0 5%;
}
.dividerOrgCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-height: 9px;
}
.reviewHeading {
  color: #20184e;
  font-family: Nunito;
  max-width: 600px;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: capitalize;
}
.demoBtn {
  border-radius: 40px;
  background: #18afba;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-height: 55px;
  box-shadow: none;
  text-transform: capitalize;
}
.bg-theme-white {
  background-color: #F2F4F6;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .reviewHeading {
    font-size: 38px;
    max-width: 619px;
  }
}

@media screen and (max-width: 767px) {
  .reviewHeading {
    font-size: 38px;
    max-width: 619px;
  }
}

@media screen and (max-width: 470px) {
  .reviewHeading {
    font-size: 35px;
  }
}
</style>

<style>
.reviewCarosel {
  position: relative;
}
.reviewCarosel .carousel__next {
  position: absolute;
  top: 100%;
  left: 40px;
  color: white;
  background: #18afba;
  border-radius: 100%;
}
.reviewCarosel .carousel__prev {
  position: absolute;
  top: 100%;
  left: 0px;
  color: white;
  background: #18afba;
  border-radius: 100%;
}
.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: #18afba;
}
</style>
