<template>
  <div class="position-relative mt-16">
    <div
      v-motion
      :initial="{
        y: 100,
      }"
      :visible="{
        y: 0,

        transition: {
          duration: 500,
        },
      }"
    >
      <v-img class="laptopMockup floating-img" :src="smart" />
    </div>
    <div class="bg-theme mainWeb min-height-600">
      <div class="d-flex justify-center pb-8">
        <v-btn class="signUp mb-16"> Sign Up Now </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import smart from "../assets/images/macbook.png";
export default {
  data() {
    return {
      smart: smart,
    };
  },
};
</script>
<style scoped>
.laptopMockup {
  /* position: absolute;
  left: 0;
  right: 0;
  min-width: 80vw; */
}
.mainWeb {
  background-image: url(../assets//images/bg-circle.png);
  background-size: contain;
}
.laptopMockup {
  margin-bottom: -44% !important;
  width: 80vw;
  margin: auto;
}
.min-height-600 {
  min-height: 600px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;
}
.signUp {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 40px;
  background: #18afba;
  text-transform: capitalize;
  box-shadow: none;
  height: 57px;
  margin: auto;
  width: 192px;
}
.bg-theme {
  background-color: #002a52;
}
</style>
