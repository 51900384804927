<template>
  <div>
    <div class="bg-theme">
      <CommonHeader />
      <div>
        <v-row>
          <v-col cols="12" lg="6" class="d-flex justify-center align-center">
            <div class="Holder">
              <h1 class="aboutSectionHeading">
                Latest News & Learning Resources
              </h1>
              <v-divider class="dividerNewsCard mt-2 mb-4"></v-divider>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="position-relative">
              <v-img
                v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 500,
                  },
                }"
                max-height="400px"
                :src="blog"
              />
              <v-img
                max-width="400px"
                min-width="399px"
                class="floating-img"
                :src="blue"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- <PackageCom /> -->
    <div style="padding: 10%" class="bg-white-theme">
      <div class="singblogImg">
        <v-card elevation-0 flat class="single-blog-card" style="padding: 2%">
          <div class="blog-img-div">
            <img class="blogimg" :src="imgurl" />
          </div>
          <div class="singleBlogtext">
            <p class="postOn">Posted on</p>
            <p class="datestyle">{{ created_at }}</p>
            <h1 class="singleBlogh1">{{ posts.title }}</h1>
            <p class="singleBlogP">
              {{ posts.body }}
            </p>
          </div>
        </v-card>
      </div>
    </div>
    <ReviewsCom />
    <FooterCom />
  </div>
</template>

<script>
import axios from "axios";
import blog from "../assets/images/blog-two.png";
import box from "../assets/images/box.png";
import blue from "../assets/images/blue.png";
import ReviewsCom from "@/components/ReviewsCom";
import FooterCom from "@/components/FooterCom";
import CommonHeader from "../components/CommonNavbar";
import blogImage from "../assets/images/2.png";
export default {
  components: {
    ReviewsCom,
    FooterCom,
    CommonHeader,
  },
  data() {
    return {
      posts: [],
      blog: blog,
      blue: blue,
      box: box,
      blogImage: blogImage,
      imgurl: null,
      created_at: null,
    };
  },
  created() {
    const postId = this.$route.params.id;
    console.log(postId);
    const userId = 30;
    this.getSinglePost(postId, userId);
  },
  methods: {
    async getSinglePost(postId, userId) {
      try {
        const response = await axios.post(
          "http://127.0.0.1:8000/api/getSinglePosts",
          {
            user_id: userId,
            post_id: postId,
          }
        );

        if (response) {
          this.posts = response.data.data;
          //   this.posts = response.data.map((post) => ({
          //     ...post,
          //     image: `http://127.0.0.1:8000/images/${post.image}`,
          //     created_at: this.formatDate(post.created_at),
          //   }));
          this.imgurl = `http://127.0.0.1:8000/images/${this.posts.image}`;
          (this.created_at = this.formatDate(this.posts.created_at)),
            console.log("single post response", this.posts);
        } else {
          this.error = response.data.message;
        }
      } catch (error) {
        this.error = "An error occurred fetching the post: " + error.message;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { year: "numeric", month: "long", day: "numeric" };
      return date.toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style scoped>
.singleBlogtext {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 2%;
}
.datestyle {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: rgba(0, 42, 82, 1);
}
.postOn {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: rgba(173, 171, 183, 1);
}
.singleBlogh1 {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  color: rgba(32, 24, 78, 1);
}
.singleBlogP {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  text-align: left;
  color: rgba(173, 171, 183, 1);
  margin-left: 1%;
}
.singblogImg {
}
.blog-img-div {
  height: 30vw;
}
.single-blog-card {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.blogimg {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  border-radius: 20px;
}
.postedOn {
  color: #adabb7;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.newsCardDate {
  color: #002a52;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.newsCardText {
  color: #adabb7;
  text-align: start;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.newsCardHeading {
  color: #20184e;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  text-align: start;
  font-weight: 700;
  line-height: 120%;
}
.bg-white-theme {
  background: #f2f4f6;
}
.aboutPlatforms {
  color: #002a52;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 700px;
  font-weight: 700;
  line-height: 50px;
}
.aboutPlatformsText {
  color: #002a52;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.5;
}
.aboutSectionHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 500px;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.aboutSectionParagraph {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  max-width: 570px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  opacity: 0.7;
}
.bg-theme {
  background-color: #002a52;
  background-image: url(../assets//images/bg-circle.png);
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-width: 44px;
  min-height: 9px;
}
.floating-img {
  position: absolute;
  bottom: -100px;
  right: 10%;
}
.blogCard {
  border-radius: 20px;
  background: #fff;
  width: 90%;
  padding: 20px;
}
.Holder {
  padding: 0 2%;
}
@media screen and (max-width: 470px) {
  .aboutSectionHeading {
    font-size: 35px;
    text-align: center;
  }
  .dividerNewsCard {
    margin: auto;
  }
}
</style>
