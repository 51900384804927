<template>
  <div style="background-color: #f2f4f6; padding-top: 10%" class="pb-16">
    <v-divider class="dividerCard"></v-divider>
    <p class="statHeading">Your Suitable packaage for your needs</p>
    <p class="statParagraph">
      Our powerful free plan gives you access to core Tazkily features.
    </p>

    <div class="mb-10">
      <div class="d-flex justify-center my-5 mb-16">
        <span class="timeSpan">Monthly</span>
        <span class="switch mx-3">
          <input id="switch-rounded" type="checkbox" v-model="isYearly" />
          <label for="switch-rounded"></label>
        </span>
        <span class="timeSpan">Annualy</span>
      </div>
      <v-row class="plr-15">
        <v-col cols="12" lg="4" md="6" class="pricing-col-1">
          <v-card
            min-width="300px"
            class="pricingCard"
            @mouseover="changeImage1"
            @mouseleave="restoreImage1"
          >
            <div class="bg-planning-top">
              <p class="planningHeading">Free plan</p>
              <p class="planningTxt">
                <span class="planningSpan">${{ freePlan }}</span>
                / Life time
              </p>
            </div>
            <div class="pricingSection py-8">
              <div>
                <p class="d-flex preSection">
                  <v-img
                    max-width="20px"
                    :src="isHovered1 ? checkedWhite : checkedBlue"
                  /><span>Today & Planning view</span>
                </p>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered1 ? checkedWhite : checkedBlue"
                  /><span>Checklist templates</span>
                </p>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered1 ? checkedWhite : checkedBlue"
                  /><span>Calendar integration</span>
                </p>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered1 ? checkedWhite : checkedBlue"
                  /><span>Browser extension</span>
                </p>
              </div>
              <div>
                <v-btn class="getStarted mt-6"> Get Started </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" md="6" class="pricing-col-2">
          <v-card
            min-width="300px"
            class="pricingCard"
            @mouseover="changeImage2"
            @mouseleave="restoreImage2"
          >
            <div class="bg-planning-top">
              <p class="planningHeading">Basic plan</p>
              <p class="planningTxt">
                <span class="planningSpan">${{ basicPlan }}</span>
                / Per Month
              </p>
            </div>
            <div class="pricingSection py-8">
              <div>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered2 ? checkedWhite : checkedBlue"
                  /><span>Everything in Free</span>
                </p>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered2 ? checkedWhite : checkedBlue"
                  /><span>Checklist templates</span>
                </p>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered2 ? checkedWhite : checkedBlue"
                  /><span>Premium integration</span>
                </p>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered2 ? checkedWhite : checkedBlue"
                  /><span>30-day trial</span>
                </p>
              </div>
              <div>
                <v-btn class="getStarted mt-6"> Get Started </v-btn>
              </div>
            </div>
          </v-card></v-col
        >
        <v-col cols="12" lg="4" md="12" class="pricing-col-3">
          <v-card
            min-width="300px"
            class="pricingCard"
            @mouseover="changeImage3"
            @mouseleave="restoreImage3"
          >
            <div class="bg-planning-top">
              <p class="planningHeading">Advance plan</p>
              <p class="planningTxt">
                <span class="planningSpan">${{ advancePlan }}</span>
                / Per Month
              </p>
            </div>
            <div class="pricingSection py-8">
              <div>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered3 ? checkedWhite : checkedBlue"
                  /><span>Today & Planning view</span>
                </p>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered3 ? checkedWhite : checkedBlue"
                  /><span>Unlimited members</span>
                </p>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered3 ? checkedWhite : checkedBlue"
                  /><span>Dedicated support</span>
                </p>
                <p class="d-flex">
                  <v-img
                    max-width="20px"
                    :src="isHovered3 ? checkedWhite : checkedBlue"
                  /><span>All advance features*</span>
                </p>
              </div>
              <div>
                <v-btn class="getStarted mt-6"> Get Started </v-btn>
              </div>
            </div>
          </v-card></v-col
        >
      </v-row>
    </div>
  </div>
</template>
<script>
import checkedWhite from "../assets/images/checkedWhite.svg";
import checkedBlue from "../assets/images/checkedBlue.svg";
export default {
  data() {
    return {
      isYearly: false,
      checkedWhite: checkedWhite,
      checkedBlue: checkedBlue,
      freePlan: 50, // Initial free price
      basicPlan: 70, // Initial basic price
      advancePlan: 100, // Initial advance  price
      animationDirection: 1,
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
    };
  },
  computed: {
    // Update freePlan based on isYearly
    updatedFreePrice() {
      return this.isYearly ? 70 : 50; // Adjust the prices as needed
    },
    updatedBasicPrice() {
      return this.isYearly ? 100 : 70; // Adjust the prices as needed
    },
    updatedAdvancePrice() {
      return this.isYearly ? 150 : 100; // Adjust the prices as needed
    },
  },
  watch: {
    // Watch for changes in isYearly and animate price change
    updatedFreePrice(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.animationDirection = newVal > oldVal ? 1 : -1; // Set animation direction
        this.animateFreePriceChange(oldVal, newVal);
      }
    },
    updatedBasicPrice(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.animationDirection = newVal > oldVal ? 1 : -1; // Set animation direction
        this.animateBasicPriceChange(oldVal, newVal);
      }
    },
    updatedAdvancePrice(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.animationDirection = newVal > oldVal ? 1 : -1; // Set animation direction
        this.animateAdvancePriceChange(oldVal, newVal);
      }
    },
  },
  methods: {
    // For Free Plan Card
    changeImage1() {
      this.isHovered1 = true;
    },
    restoreImage1() {
      this.isHovered1 = false;
    },
    // For Basic Plan Card
    changeImage2() {
      this.isHovered2 = true;
    },
    restoreImage2() {
      this.isHovered2 = false;
    },
    // For Advance Plan Card
    changeImage3() {
      this.isHovered3 = true;
    },
    restoreImage3() {
      this.isHovered3 = false;
    },
    // Animate the price change
    animateFreePriceChange(oldPrice, newPrice) {
      let start = oldPrice;
      const duration = 1000; // Duration in milliseconds
      const step =
        (this.animationDirection * Math.abs(newPrice - oldPrice)) /
        (duration / 60); // Calculate step based on duration and direction

      const interval = setInterval(() => {
        start += step;
        this.freePlan = Math.round(start);
        if (
          (this.animationDirection === 1 && start >= newPrice) ||
          (this.animationDirection === -1 && start <= newPrice)
        ) {
          clearInterval(interval);
          this.freePlan = newPrice;
        }
      }, 16); // Approx. 60 frames per second
    },
    animateBasicPriceChange(oldPrice, newPrice) {
      let start = oldPrice;
      const duration = 1000; // Duration in milliseconds
      const step =
        (this.animationDirection * Math.abs(newPrice - oldPrice)) /
        (duration / 60); // Calculate step based on duration and direction

      const interval = setInterval(() => {
        start += step;
        this.basicPlan = Math.round(start);
        if (
          (this.animationDirection === 1 && start >= newPrice) ||
          (this.animationDirection === -1 && start <= newPrice)
        ) {
          clearInterval(interval);
          this.basicPlan = newPrice;
        }
      }, 16); // Approx. 60 frames per second
    },
    animateAdvancePriceChange(oldPrice, newPrice) {
      let start = oldPrice;
      const duration = 1000; // Duration in milliseconds
      const step =
        (this.animationDirection * Math.abs(newPrice - oldPrice)) /
        (duration / 60); // Calculate step based on duration and direction

      const interval = setInterval(() => {
        start += step;
        this.advancePlan = Math.round(start);
        if (
          (this.animationDirection === 1 && start >= newPrice) ||
          (this.animationDirection === -1 && start <= newPrice)
        ) {
          clearInterval(interval);
          this.advancePlan = newPrice;
        }
      }, 16); // Approx. 60 frames per second
    },
  },
};
</script>
<style scoped>
.pricing-col-1 {
  display: flex;
  justify-content: right;
}
.pricing-col-2 {
  display: flex;
  justify-content: center;
}
.pricing-col-3 {
  display: flex;
  justify-content: left;
}
.dividerCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-height: 9px;
  margin: 0 auto 10px;
}
.plr-15 {
  padding-right: 15% !important;
  padding-left: 15% !important;
}
.bg-planning-top {
  background-image: linear-gradient(to bottom, #095574 0%, #002a52 100%);
  padding: 20px 30px;
}

.pricingCard {
  color: #03375c;
  border-radius: 20px;
  box-shadow: none;
}
.timeSpan {
  color: #03375c;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}
.pricingCard .getStarted {
  color: #03375c;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 40px;
  text-transform: capitalize;
  background: rgba(145, 167, 192, 0.12);
  min-height: 55px;
  width: 157px;
  box-shadow: none;
}
.pricingSection {
  transition: background-color 0.3s ease;
}
.pricingCard .pricingSection {
  padding: 0 30px;
  background: #f2f4f6;
}
.pricingCard:hover .pricingSection {
  background-color: #002a52;
  color: white;
}
/* .pricingCard:hover .pricingSection img {
  color: white;
} */
.pricingCard:hover .getStarted {
  border-radius: 40px;
  background: #18aeb9;
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.planningSpan {
  color: #fff;
  text-align: center;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
  text-transform: capitalize;
}
.pricingSection p {
  margin: 10px 0;
}
.pricingSection p span {
  margin-left: 12px;
}
.planningHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
  line-height: 160%;
}
.planningTxt {
  color: #fff;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  margin-top: 12px;
  font-weight: 400;
  line-height: 160%;
}
.statHeading {
  color: #20184e;
  text-align: center;
  font-family: Nunito;
  font-size: 48px;
  max-width: 650px;
  margin: auto;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 52.8px */
  text-transform: capitalize;
}
.statParagraph {
  color: #adabb7;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}

/* =========== */
.switch {
  display: inline-block;
}

.switch input {
  display: none;
}

.switch label {
  display: block;
  width: 104px;
  height: 30px;
  padding: 3px;
  border-radius: 15px;
  border: 2px solid #002a52;
  cursor: pointer;
  transition: 0.3s;
  background-color: white ;

}

.switch label::after {
  content: "";
  display: inherit;
  width: 40px;
  height: 20px;
  border-radius: 12px;
  background: #002a52;
  transition: 0.3s;
}

.switch input:checked ~ label {
  border-color: #002a52;
}

.switch input:checked ~ label::after {
  translate: 55px 0;
  background: #002a52;
}

.switch input:disabled ~ label {
  opacity: 0.5;
  cursor: not-allowed;
}
@media (max-width: 1280px) {
  .pricing-col-1 {
    display: flex;
    justify-content: center;
  }
  .pricing-col-2 {
    display: flex;
    justify-content: center;
  }
  .pricing-col-3 {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  /* Your CSS styles for MD screens go here */
  .statHeading {
    font-size: 38px;
    max-width: 619px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .statHeading {
    font-size: 38px;
    max-width: 619px;
  }
  .statParagraph {
    font-size: 16px;
  }
}
</style>
