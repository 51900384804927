import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ProductWebView from "../views/ProductWebView.vue";
import ProductMobileView from "../views/ProductMobileView.vue";
import PricingView from "../views/PricingView.vue";
import BlogsView from "../views/BlogsView.vue";
import ContactView from "../views/ContactView.vue";
import LoginView from "@/views/LoginView.vue";
import ProductFaculty from "@/views/ProductFacultyView.vue";
import ProductTransplant from "@/views/ProductTransplantView.vue";
import SingleBlogs from "@/views/SingleBlogview.vue"
import ProductView from "@/views/ProductView.vue"
import PrivacyPolicy from "@/views/PrivacyPolicyView.vue"
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "About",
    component: AboutView,
  },

  {
    path: "/Features",
    name: "/Features",
    component: ProductView,
  },
  {
    path: "/product-web",
    name: "ProductWeb",
    component: ProductWebView,
  },
  {
    path: "/product-mobile",
    name: "ProductMobile",
    component: ProductMobileView,
  },
  {
    path: "/products",
    name: "ProductTransplant",
    component: ProductTransplant,
  },
  {
    path: "/product-faculty",
    name: "ProductFaculty",
    component: ProductFaculty,
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: PricingView,
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: BlogsView,
  },
  {
    path: "/singleblogs/:id",
    name: "SingleBlog",
    component: SingleBlogs,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
