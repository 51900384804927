<template>
    <div class="full-bg">
      <div class="pt-16 feature-bg">
        <v-divider class="dividerNewsCard mt-16 mb-4"></v-divider>
        <h1 class="teamHeading">Unmatched Features to<br> Get You the Most</h1>
        <p class="featuresPara mb-16 mt-2">
          Get back your time and increase your Medicare/Medicaid reimbursements.<br>
          Medicare and Medicaid Time Study is a fully scalable, flexible and most comprehensive SAAS<br> platform that helps you get better productivity and higher reimbursement for your time.       </p>
      </div>
      <div style="padding: 0 10%;">
        <div>
          <Carousel
            class="reviewCarosel"
            :items-to-show="3"
            :breakpoints="carouselBreakpoints"
            :wrap-around="true"
          >
            <Slide v-for="(slide, index) in carouselSlides" :key="index">
              <!-- <v-card class="organization-card"> -->
              <div class="carousel__item pa-5">
                <h2 class="orgCardHeading">{{ slide.heading }}</h2>
                <v-divider class="dividerNewsCard smallDivider ma-0 mt-3 mb-4"></v-divider>
                <p class="orgCardText ms-5">{{ slide.text }}</p>
              </div>
              <!-- </v-card> -->
            </Slide>
            <template #addons>
              <Pagination class="mt-16" />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { defineComponent } from "vue";
  import { Carousel, Slide, Pagination } from "vue3-carousel";
  import "vue3-carousel/dist/carousel.css";
  export default defineComponent({
    name: "WrapAround",
    components: {
      Carousel,
      Slide,
      Pagination,
    },
    data() {
      return {
        carouselBreakpoints: {
          1260: { itemsToShow: 3 },
          1060: { itemsToShow: 2 },
          860: { itemsToShow: 2 },
          600: { itemsToShow: 1 },
          0: { itemsToShow: 1 },
        },
        carouselSlides: [
          {
            heading: "One Solution for All Time Studies",
            text: "Cut your costs, improve productivity by embracing a single solution for all aspects of time studies.",
          },
          {
            heading: "Analytics & Reporting",
            text: "Get complete and real time insights in your operations with our powerful analytics and reporting tools",
          },
          {
            heading: "Permission-Based Access",
            text: "Easily create workflows and provide permission-based access to each of your team members with our scalable solution.",
          },
          {
            heading: "Auto-Reminders!",
            text: "MTS keep you updated with your progress with our in-built auto reminder features. ",
          },
          {
            heading: "Mobile Reporting",
            text: "Our Android and IOS based apps allow you to easily report your time while on-the-move ",
          },
         
          {
            heading: "Smart Data",
            text: "MTS makes data entry for time study smarter. WIth instant validation, we ensure the integrity of the data",
          },
        ],
      };
    },
  });
  </script>
  <style scoped>
  .smallDivider{
    min-width: 29px !important;
      min-height: 6px !important;
  }
  .full-bg{
    background-color: #002a520b;
  }
  .feature-bg{
    background-image: url(../assets/images/half.png);
    background-position: right;
    background-position: right top;
  }
  .dividerNewsCard {
    border-radius: 57px;
    border: 0;
    background: #18afba;
    opacity: 1;
    max-width: 44px;
    margin: auto;
    min-width: 44px;
    min-height: 9px;
  }
  .teamHeading {
    color: #002a52;
    text-align: center;
    font-family: Nunito;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
  }
  .featuresPara {
    color: #002a52b6;
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .carousel__item {
    border-radius: 20px;
    background: #002a52;
    color: white;
    /* min-width: 345px; */
    max-width: 346px;
    min-height: 218px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .orgCardHeading {
    color: #fff;
    font-family: Nunito;
    font-size: 24px;
    text-align: start;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 31.2px */
  }
  .orgCardText {
    color: #adabb7;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    text-align: start;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
  }
  @media (max-width: 991px) {
    /* Your CSS styles for MD screens go here */
    .teamHeading {
      font-size: 38px;
      max-width: 619px;
    }
    .featuresPara {
      font-size: 18px;
    }
  }
  
  /* For small screens (SM) */
  @media screen and (max-width: 767px) {
    /* Your CSS styles for SM screens go here */
    .teamHeading {
      font-size: 38px;
      max-width: 619px;
    }
  }
  </style>
  