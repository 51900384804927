<template>
  <div style="padding: 5% 5% 8%" class="bg-white-theme">
    <div class="d-flex flex-column justify-center align-center">
      <v-divider class="dividerNewsCard mb-4"></v-divider>
      <h1 class="teamHeading mb-8">Meet Our Team</h1>
    </div>
    <div style="padding: 0 10%">
      <v-row class="mt-16">
        <v-col cols="12" lg="4">
          <div class="d-flex justify-center my-16">
            <v-card class="teamCard">
              <v-img
                max-width="150px"
                min-width="100px"
                class="memberImg"
                :src="teamImg2"
              />
              <p class="cardTeamHeading">Syed Gilani</p>
              <p class="cardTeamText">CEO and Founder</p>
              <div class="d-flex justify-center arrowHolder">
                <v-btn
                  :ripple="false"
                  class="toggleBtn"
                  @click="toggleExpansionTwo"
                >
                  <v-img min-width="44px" width="100%" :src="arrow" />
                </v-btn>
              </div>
              <v-expansion-panels
                class="expansionPanelTime"
                v-model="expansionPanelTwo"
              >
                <v-expansion-panel
                  text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima"
                >
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
        </v-col>

        <v-col cols="12" lg="4">
          <div class="d-flex justify-center my-16">
            <v-card class="teamCard">
              <v-img
                max-width="150px"
                min-width="99px"
                class="memberImg"
                :src="teamImg"
              />
              <p class="cardTeamHeading">Dr. Waheed Tajik</p>
              <p class="cardTeamText">CEO and Founder</p>
              <div class="d-flex justify-center arrowHolder">
                <v-btn
                  :ripple="false"
                  class="toggleBtn"
                  @click="toggleExpansionOne"
                >
                  <v-img min-width="33px" width="100%" :src="arrow" />
                </v-btn>
              </div>
              <v-expansion-panels
                class="expansionPanelTime"
                v-model="expansionPanelOne"
              >
                <v-expansion-panel
                  text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima"
                >
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
        </v-col>
       
        <v-col cols="12" lg="4">
          <div class="d-flex justify-center my-16">
            <v-card class="teamCard">
              <v-img
                max-width="150px"
                min-width="100px"
                class="memberImg"
                :src="teamImg3"
              />
              <p class="cardTeamHeading">Zeeshan Ahmad</p>
              <p class="cardTeamText">CTO</p>
              <div class="d-flex justify-center arrowHolder">
                <v-btn
                  :ripple="false"
                  class="toggleBtn"
                  @click="toggleExpansionThree"
                >
                  <v-img min-width="44px" width="100%" :src="arrow" />
                </v-btn>
              </div>
              <v-expansion-panels
                class="expansionPanelTime"
                v-model="expansionPanelThree"
              >
                <v-expansion-panel
                  text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima"
                >
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import teamImg from "../assets/images/drwaheed.png";
import teamImg2 from "../assets/images/syedgilani.png";
import teamImg3 from "../assets/images/zeeshan.png";
import arrow from "../assets/images/toggle.png";
export default {
  data() {
    return {
      teamImg: teamImg,
      teamImg2: teamImg2,
      teamImg3: teamImg3,
      arrow: arrow,
      expansionPanelOne: 1,
      expansionPanelTwo: 1,
      expansionPanelThree: 1,
    };
  },
  methods: {
    toggleExpansionOne() {
      this.expansionPanelOne = this.expansionPanelOne === 0 ? 1 : 0;
    },
    toggleExpansionTwo() {
      this.expansionPanelTwo = this.expansionPanelTwo === 0 ? 1 : 0;
    },
    toggleExpansionThree() {
      this.expansionPanelThree = this.expansionPanelThree === 0 ? 1 : 0;
    },
  },
};
</script>
<style>
.expansionPanelTime .v-expansion-panel__shadow {
  box-shadow: none !important;
}
</style>
<style scoped>
.arrowHolder {
  position: absolute;
  bottom: -20px;
  left: 40%;
  z-index: 999;
}
.memberImg {
  margin: auto;
  min-width: 100px !important;
  position: relative !important;
  z-index: 999999;
  top: -95px !important;
  /* left: 35% !important; */
}
.cardTeamHeading {
  color: #20184e;
  /* margin-top: 20px; */
  text-align: center;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.cardTeamText {
  color: #adabb7;
  text-align: center;
  margin-bottom: 20px;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.bg-white-theme {
  background: #f2f4f6;
}
.toggleBtn {
  border-radius: 100%;
  box-shadow: none;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: auto;
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-width: 44px;
  min-height: 9px;
}
.teamHeading {
  color: #002a52;
  text-align: center;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 91.667% */
}
.teamCard {
  /* padding-top: 100px; */
  border-radius: 20px;
  background: #fff;
  max-width: 400px;
  position: relative;
  min-width: 310px;
  overflow: visible;
  box-shadow: none;
  /* position: relative; */
  padding: 20px 0;
}

@media (max-width: 991px) {
  /* Your CSS styles for MD screens go here */
  .teamHeading {
    font-size: 38px;
    max-width: 619px;
  }
  .cardTeamHeading {
    font-size: 20px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .teamHeading {
    font-size: 38px;
    max-width: 619px;
  }
}
</style>
