<template>
  <div class="bg-theme">
    <div class="d-flex justify-center">
      <v-img
        class="my-10"
        style="cursor: pointer"
        max-width="130px"
        min-width="120px"
        :src="logo"
        @click="goToRoot"
      />
    </div>
    <v-divder></v-divder>

    <div class="d-flex justify-center">
      <!-- Centering the image -->
      <v-img :src="top" max-width="1300" />
    </div>

    <div class="footer-Links mb-7 mt-5">
      <router-link
        class="routerLink mx-5 text-decoration-none margin-redu"
        to="/"
      >
        <p class="footerLinks">Home</p>
      </router-link>
      <div class="navbar-login mt-3"></div>
      <router-link
        class="routerLink mx-5 text-decoration-none margin-redu"
        to="/about"
      >
        <p class="footerLinks">About</p>
      </router-link>
      <div class="navbar-login mt-3"></div>
      <!-- <p class="footerLinks">Products</p> -->
      <router-link
        class="routerLink mx-5 text-decoration-none margin-redu"
        to="/products"
      >
        <v-list-item-title
          class="footerLinks"
          v-bind:class="{ 'navbar-link-active': isProducts }"
          >Products</v-list-item-title
        >
      </router-link>
      <div class="navbar-login mt-3"></div>
      <!-- <router-link class="routerLink mx-5 text-decoration-none" to="/pricing">
            <p class="footerLinks">Pricing</p>
        </router-link>
        <div class="navbar-login mt-3" >
                </div> -->
      <!-- <router-link class="routerLink mx-5 text-decoration-none" to="/blogs">
            <p class="footerLinks">Blogs</p>
        </router-link> -->
      <router-link
        class="routerLink mx-5 text-decoration-none margin-redu"
        to="/features"
      >
        <p class="footerLinks">Features</p>
      </router-link>
      <div class="navbar-login mt-3"></div>
      <router-link
        class="routerLink mx-5 text-decoration-none margin-redu"
        to="/contact"
      >
        <p class="footerLinks">Contact</p>
      </router-link>
      <div class="navbar-login mt-3"></div>
      <router-link
        class="routerLink mx-5 text-decoration-none margin-redu"
        to="/privacypolicy"
      >
        <p class="footerLinks">Privacy policy</p>
      </router-link>
    </div>

    <div class="d-flex justify-center">
      <!-- Centering the image -->
      <v-img :src="top" max-width="1300" />
    </div>
    <v-divder></v-divder>
    <div class="d-flex justify-center py-3 mt-10">
      <div class="iconDiv d-flex justify-space-between py-3 mb-10">
        <a :href="your_facebook_link" target="_blank">
          <v-img
            min-width="20px"
            max-width="20px"
            max-height="20px"
            :src="fb"
          />
        </a>

        <a :href="your_twitter_link" target="_blank">
          <v-img
            class="mx-8"
            min-width="20px"
            max-width="20px"
            :src="twitter"
          />
        </a>
        <a :href="your_instagram_link" target="_blank">
          <v-img min-width="20px" max-width="20px" :src="insta" />
        </a>
      </div>
    </div>
  </div>

  <div class="bg-dark-theme">
    <p class="footer-text pa-3">
      © 2024 Medicare Timestudy. All Rights Reserved. Designed and Developed by
      TGI.
    </p>
  </div>
</template>

<script>
import logo from "../assets/images/timestudy.png";
import fb from "../assets/images/Facebook.png";
import twitter from "../assets/images/Twitter.png";
import insta from "../assets/images/Instagram.png";
import top from "../assets/images/top-line.png";

export default {
  data() {
    return {
      logo: logo,
      fb: fb,
      twitter: twitter,
      insta: insta,
      your_facebook_link:
        "https://www.facebook.com/profile.php?id=61558624476113",
      your_twitter_link: "https://twitter.com/medicareti23922",
      your_instagram_link: "https://www.instagram.com/medicaretimestudy/",
      top: top,
      required: true,
    };
  },
  methods: {
    goToRoot() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.bg-theme {
  background-color: #002a52;
}

.bg-dark-theme {
  background: #002243;
}

.iconDiv {
  min-width: 100px;
}

.footer-Links {
  display: flex;
  justify-content: space-evenly;
  width: 60vw;
  margin: auto;
}
@media (min-width: 842px) and (max-width: 1211px) {
  .footer-Links {
    width: 70vw;
  }
  .margin-redu {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 842px) {
  .footer-Links {
    display: block;
  }
}

.footer-text {
  color: #ccc;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.footerLinks {
  color: #fff;
  text-align: center;
  font-family: Gowun Dodum;
  letter-spacing: 2px;
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footerLinks {
  margin: 13px 0%;
  height: 10px;
}

.routerLink {
  text-decoration: none;
}

@media (max-width: 680px) {
  .footer-Links {
    display: block;
  }
}

.productsList {
  border-radius: 10px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  width: 248px;
  /* height: 84px !important; */
  box-shadow: none !important;
}

.navbar-link {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  text-decoration: none;
  color: #fff;
}
.navbar-login {
  border: 1px solid transparent; /* Set a transparent border to reserve space */
  border-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #ffffff 49%,
    rgba(0, 0, 0, 0) 100%
  );
  border-image-slice: 1; /* Ensure the border image covers the entire border */
  @media only screen and (max-width: 842px) {
    border: none; /* Hide the border on smaller screens */
  }
}
</style>
