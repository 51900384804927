<template>
  <div class="text-center">
    <p>Coming Soon</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap");
.text-center{
 font-size:  50px;
 color: #20184E;
 font-family: Gowun Dodum;
 margin-top: 30px;
}
</style>