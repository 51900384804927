<template>
  <div>
    <div class="bg-theme">
      <CommonHeader />
      <div>
        <v-row>
          <v-col cols="12" lg="6" class="d-flex justify-center align-center">
            <div class="AboutHolder">
              <h1 class="aboutSectionHeading ml-13">
                Built for healthcare by <br />healthcare professional
              </h1>
              <v-divider class="dividerNewsCard mt-2 mb-4 ml-13"></v-divider>
              <div>
                <p class="aboutSectionParagraph ps-16">
                  When experienced healthcare executives meet decades of
                  technology expertise in the global hub of healthcare, the best
                  solution for medical time study is born! Medicare Time Study
                  is not only built for collecting and reporting time for
                  medicare but also covers a wide range of areas including
                  Medicaid, hospital faculty and transplant centers.
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="position-relative">
              <v-img
                v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 500,
                  },
                }"
                :src="about"
                max-height="650"
              />
              <v-img
                max-width="400px"
                min-width="399px"
                class="floating-img"
                :src="blue"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div style="padding: 10% 5% 2%" class="bg-white-theme">
      <v-divider class="dividerNewsCard mb-4"></v-divider>
      <h1 class="aboutPlatforms">
        Our Platform is Easy, Powerful and Budget Friendly!
      </h1>
      <p class="mb-5 mt-3 aboutPlatformsText">It’s just a click away<br /></p>
      <p class="mb-5 mt-3 aboutPlatformsText">
        MTS takes a unique view of the traditional time logging practices at
        healthcare practices for medicare and medicaid reimbursements. We have
        combined the power of A.I. with robust data collection, reporting and
        reimbursement tools to create a next-generation Medicare & Medicaid Time
        Study platform.<br />
      </p>
      <p class="aboutPlatformsText mb-16">
        We understand that the most important commodity for every healthcare
        professional is their time. Getting maximum reimbursement from Medicare
        and Medicaid with the least amount of effort is what everyone is looking
        for. You may have a choice of either using traditional Excel sheets or
        onboarding one of the most expensive solutions that may not even do
        everything you need.With MTS, you don’t need to compromise quality and
        don’t have to come up with a huge budget in these tough financial times.
      </p>
      <v-img :src="aboutMain" />
    </div>
  </div>
</template>
<script>
import CommonHeader from "../components/CommonNavbar";
import about from "../assets/images/aboutImage.png";
import blue from "../assets/images/blue.png";
import aboutMain from "../assets/images/team.png";
export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      about: about,
      blue: blue,
      aboutMain: aboutMain,
    };
  },
};
</script>
<style>
.floating-img {
  animation-name: floatAnimation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
</style>
<style scoped>
.bg-white-theme {
  background: #f2f4f6;
}
.aboutPlatforms {
  color: #002a52;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 700px;
  font-weight: 700;
  line-height: 50px;
}
.aboutPlatformsText {
  color: #002a52;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.5;
}
.AboutHolder {
  padding: 0 2%;
}
.aboutSectionHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.aboutSectionParagraph {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  max-width: 570px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  opacity: 0.7;
}
.bg-theme {
  background-color: #002a52;
  background-image: url(../assets//images/bg-circle.png);
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-height: 9px;
}
.floating-img {
  position: absolute;
  bottom: -100px;
  right: 10%;
}
@media (max-width: 991px) {
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
  .aboutPlatformsText {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
}
@media screen and (max-width: 470px) {
  .aboutSectionHeading {
    font-size: 35px;
    text-align: center;
  }
  .aboutPlatforms {
    font-size: 35px;
    line-height: 40px;
  }
  .aboutSectionParagraph {
    padding-left: 0 !important;
    text-align: center;
  }
  .dividerNewsCard {
    margin: auto;
  }
}
</style>
