<template>
  <div>
    <HomeHeader />
    <AboutUs />
    <!-- <PackageCom /> -->
    <StatsCom />
    <ReviewsCom />
    <NewsCom/>
    <FooterCom/>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import HomeHeader from "@/components/HomeHeader";
import AboutUs from "@/components/AboutUs";
import StatsCom from "@/components/StatsCom";
// import PackageCom from "@/components/PackageCom";
import ReviewsCom from "@/components/ReviewsCom";
import NewsCom from "@/components/NewsCom";
import FooterCom from "@/components/FooterCom";

export default defineComponent({
  name: "HomeView",

  components: {
    HomeHeader,
    AboutUs,
    // PackageCom,
    StatsCom,
    ReviewsCom,
    NewsCom,
    FooterCom
  },
});
</script>
