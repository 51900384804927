<template>
    <div>
      <ProductMobileHeader />
      <OurFeatures/>
      <ProductMobileMain />
      <ReviewsCom />
      <FooterCom />
    </div>
  </template>
  <script>
  import { defineComponent } from "vue";
  import ProductMobileHeader from "@/components/ProductMobileHeader";
  import ProductMobileMain from "@/components/ProductMobileMain";
  import ReviewsCom from "@/components/ReviewsCom";
  import FooterCom from "@/components/FooterCom";
  import OurFeatures from "@/components/OurFeatures";
  
  export default defineComponent({
    name: "ProductMobileView",
  
    components: {
        ProductMobileHeader,
        OurFeatures,
      ProductMobileMain,
      ReviewsCom,
      FooterCom,
    },
  });
  </script>
  