<template>
<div class="bg-white-theme ">
    <div class="contnet">
        <v-divider class="dividerCard1"></v-divider>

        <h1 class="contneth1">Don’t have time to enter<br> data manually?</h1>
    </div>
    <v-row class="w-100 mt-10" style="padding: 0px 8%">
        <v-col cols="12" lg="4" md="6" sm="12" xs="12">
            <div class="d-flex justify-center align-center">
                <v-card min-width="360px" height="300" class="homeCard">
                    <v-img max-width="84px" class="mx-auto mt-8 mb-3" :src="icon1" />
                    <p class="cardHead">A.I. Powered</p>
                    <p class="cardTxt mb-8">
                        Logging your time is only a short dictation away. Have a conversation with our A.I. assistant MEDI and she will log everything for you. Review your data and submit with ease.
                    </p>
                    <v-divider class="dividerCard"></v-divider>
                </v-card>
            </div>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12" xs="12">
            <div class="d-flex justify-center align-center">
                <v-card min-width="360px"  height="300"  class="homeCard">
                    <v-img max-width="84px" class="mx-auto mt-8 mb-3" :src="icon2" />
                    <p class="cardHead"> Same Power - More Ease</p>
                    <p class="cardTxt mb-8">
                        Get the desktop power on your mobile phone without sacrificing the quality of service. A fully integrated and scalable platform on your fingertips.

                    </p>
                    <v-divider class="dividerCard"></v-divider>
                </v-card>
            </div>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12" xs="12">
            <div class="d-flex justify-center align-center">
                <v-card min-width="360px"  height="300"  class="homeCard">
                    <v-img max-width="84px" class="mx-auto mt-8 mb-3" :src="icon3" />
                    <p class="cardHead">Always Up-to-Date</p>
                    <p class="cardTxt mb-13">
                        With changing apps versions and newer features introduced, you enjoy latest and the greatest features on the mobile apps.
                    </p>
                    <v-divider class="dividerCard"></v-divider>
                </v-card>
            </div>
        </v-col>

    </v-row>
    <v-row justify="center">
    <v-col cols="auto">
      <div class="image-container">
        <v-img
          v-motion :initial="{ y: 100 }"
          :visible="{ y: 0, transition: { duration: 500 } }"
          class="smartIphone floating-img responsive-img"
          :src="smart"
        />
      </div>
    </v-col>
  </v-row>
    <div class="text-center d-flex mt-10 mb-10">
        <v-img :src="PlayStore" max-width="237"></v-img>
        <v-img :src="AppleStore" max-width="237"></v-img>

    </div>
   <div class="mb-10">
    <div class="bg-nav">
    <v-divider class="dividerCard2 mt-15"></v-divider>
    <v-row justify="center">
        <v-col class="text-center-one mt-5">
            <p>Ideal solutions for<br> physicians and surgeons</p>
            <v-btn class="ExploreMore my-3 mt-5" href="/about">Explore More</v-btn>

        </v-col>
    </v-row>
</div>

   </div>

</div>
</template>

<script>
import smart from "@/assets/images/smartPhoneFeautures.png";
import PlayStore from "@/assets/images/googlePlay.png";
import AppleStore from "@/assets/images/AppleStore.png";

export default {
    components: {},
    data() {
        return {
            smart: smart,
            PlayStore: PlayStore,
            AppleStore: AppleStore,
        };
    },
    methods: {

    },
};
</script>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
}

.responsive-img {
  max-width: 100%;
  height: auto;
  width: 1440px; /* You can set a default width or remove this if not needed */
}
.contnet {
    align-items: center;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column
}

.contneth1 {
    color: #002A52;
    text-align: center;
    font-family: Nunito;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.contnetp {
    color: #ADABB7;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
}

.dividerCard1 {

    border-radius: 57px;
    border: 0;
    background: #18afba;
    opacity: 1;
    width: 54px;
    min-height: 9px;
    margin: 34px 0px;
}
.dividerCard2 {

border-radius: 57px;
border: 0;
background: #18afba;
opacity: 1;
width: 54px;
min-height: 9px;
margin: 10px 0px;
}
.homeHeaderHodler {
    max-height: 650px;
}

.bg-theme {
    background-color: #002a52;
}

.dividerCard {
    border-radius: 57px;
    background: #002a52;
    display: none;
    opacity: 1;
    max-width: 44px;
    min-width: 44px;
    min-height: 9px;
    margin: 0 auto;
    transition: display 1s ease;
    position: absolute;
    bottom: 0;
    left: 42%;
}

.ipadImage {}

.headerTxtMain {
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 50px;
    font-style: normal;
    max-width: 1200px;
    font-weight: 700;
    margin: auto;
    line-height: 110%;
    /* 66px */
    text-transform: capitalize;
}

.bg-white-theme {
    background: white;
}

.homeCard {
    border-radius: 20px;
    background: #fff;
    position: relative;
    box-shadow: none;
}

.homeCard {
    box-shadow: 48px 37px 104px 0px #140b4823;

}

.headerParagraph {
    color: #ccc;
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 160% */
}

.getStartedBtn {
    border-radius: 40px;
    background: #18afba;
    text-transform: capitalize;
    color: #fff;
    text-align: center;
    font-family: Nunito;
    min-height: 57px;
    font-size: 18px;
    font-style: normal;
    box-shadow: none;
    font-weight: 500;
    width: 164px;
    line-height: normal;
}

.tryFreeTrialBtn {
    color: #fff;
    min-height: 57px;
    width: 164px;
    text-transform: capitalize;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    border-radius: 40px;
    background: rgba(24, 175, 186, 0.2);
    font-weight: 500;
    box-shadow: none;
    line-height: normal;
}

.cardHead {
    color: #20184e;
    text-align: center;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 33.6px */
}

.cardTxt {
    color: #adabb7;
    padding-top: 10px;
    text-align: center;
    margin: auto;
    font-family: Nunito;
    font-size: 17px;
    max-width: 200px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 25.2px */
}

/* For medium screens (MD) */
@media screen and (min-width: 768px) and (max-width: 991px) {

    /* Your CSS styles for MD screens go here */
    .headerTxtMain {
        font-size: 45px;
        max-width: 700px;
    }

    .headerParagraph {
        font-size: 16px;
    }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {

    /* Your CSS styles for SM screens go here */
    .headerTxtMain {
        font-size: 27px;
        max-width: 700px;
    }

    .headerParagraph {
        font-size: 16px;
    }
}

@media screen and (max-width: 470px) {
    .headerTxtMain {
        font-size: 35px;
    }

    .contneth1 {
        font-size: 27px !important
    }

    .contnetp {
        font-size: 13px !important;
    }
}
</style><style>
@media screen and (max-width: 767px) {

    /* Your CSS styles for SM screens go here */
    .floating-img {
        min-width: 250px !important;
        z-index: 999;
        right: 0% !important;
    }

    .contneth1 {
        font-size: 20px !important
    }

    .contnetp {
        font-size: 13px !important;
    }
}

.text-center {
    justify-content: center;
}

.fill-height {
    height: 100vh;
}

.bg-nav {
    background-image: url('../assets/images/navyBg.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dividerCard2 {
    width: 100%;
    max-width: 200px; /* Adjust the maximum width as needed */
    border-top: 1px solid white; /* Adjust the border style as needed */
    margin: 20px 0; /* Adjust the margin as needed */
}

.text-center-one {
    color: white;
    padding-top: 10px;
    text-align: center;
    margin: auto;
    font-family: Nunito;
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
}
.ExploreMore {
  border-radius: 40px;
  background: #18afba;
  color: #fff;
  text-align: start;
  font-family: Nunito;
  font-size: 18px;
  text-transform: capitalize;
  font-style: normal;
  min-height: 57px;
  width: 173px;
  font-weight: 500;
  margin: auto;
  line-height: normal;
  display: flex;
  justify-content: center;
}
</style>
