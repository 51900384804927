<template>
  <div>
    <AboutHeader />
    <TeamCom />
    <JoinUs />
    <ReviewsCom />
    <NewsCom/>

    <FooterCom />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import AboutHeader from "@/components/AboutHeader";
import TeamCom from "@/components/TeamCom";
import JoinUs from "@/components/JoinUs";
import ReviewsCom from "@/components/ReviewsCom";
import FooterCom from "@/components/FooterCom";
import NewsCom from "@/components/NewsCom";

export default defineComponent({
  name: "AboutView",

  components: {
    AboutHeader,
    TeamCom,
    JoinUs,
    ReviewsCom,
    FooterCom,
    NewsCom 

  },
});
</script>
