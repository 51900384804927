<template>
<div class="pt-16 mainStats">
    <div>
        <v-divider class="dividerCard"></v-divider>
        <p class="statsHeading mb-">Get More Done with <br>Medicare Time Study.</p>
        <p class="volume-text mb-16">Numbers that speak a volume</p>
    </div>
    <div>
        <v-row class="w-100" style="padding: 0 7%">
            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="centerGrid" style="overflow: visible;">
                <v-card class="statsCard" style="position: relative; overflow: visible;">
                    <v-img style="width: 68px; height: 100px; position: absolute; top: -50px; z-index: 88;" src="../assets/images/helikoptor.png"></v-img>
                    <p class="statsFirst">128%</p>
                    <p class="statsSecond">Boost in Time Study Participation</p>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="centerGrid" style="overflow: visible;">
                <v-card class="statsCard" style="position: relative; overflow: visible;">
                    <v-img style="width: 68px; height: 100px; position: absolute; top: -50px; z-index: 88;" src="../assets/images/clock.png"></v-img>
                    <p class="statsFirst">10M+</p>
                    <p class="statsSecond">Hours Studied</p>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="centerGrid" style="overflow: visible;">
                <v-card class="statsCard" style="position: relative; overflow: visible;">
                    <v-img style="width: 68px; height: 100px; position: absolute; top: -50px; z-index: 88;" src="../assets/images/rates.png"></v-img>
                    <p class="statsFirst">0%</p>
                    <p class="statsSecond">Churn Rate</p>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="centerGrid" style="overflow: visible;">
                <v-card class="statsCard" style="position: relative; overflow: visible;">
                    <v-img style="width: 68px; height: 100px; position: absolute; top: -50px; z-index: 88;" src="../assets/images/mobile-rates.png"></v-img>
                    <p class="statsFirst">95%</p>
                    <p class="statsSecond">Participation Rate for Mobile-only Users</p>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="centerGrid" style="overflow: visible;">
                <v-card class="statsCard" style="position: relative; overflow: visible;">
                    <v-img style="width: 68px; height: 100px; position: absolute; top: -50px; z-index: 88;" src="../assets/images/payback.png"></v-img>
                    <p class="statsFirst">3 Months</p>
                    <p class="statsSecond">Payback Period</p>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="6" xs="12" class="centerGrid" style="overflow: visible;">
                <v-card class="statsCard" style="position: relative; overflow: visible;">
                    <v-img style="width: 68px; height: 100px; position: absolute; top: -50px; z-index: 88;" src="../assets/images/increased-capture.png"></v-img>
                    <p class="statsFirst">2X-3X</p>
                    <p class="statsSecond">Increased Capture of Part A/B Hours</p>
                </v-card>
            </v-col>
        </v-row>
    </div>
</div>
</template>

<style scoped>
.mainStats {
    background-image: url(../assets/images/statCircle.png);
    background-size: contain;
    background: white;
    padding-bottom: 7%;
    margin-top: 0px !important;
}

.dividerCard {
    border-radius: 57px;
    border: 0;
    background: #18afba;
    opacity: 1;
    max-width: 44px;
    min-height: 9px;
    margin: 40px auto 10px;
}

.statsCard {
    margin-top: 40px;
    box-shadow: none !important;
    border-radius: 20px;
    background: #F2F4F6;
    width: 283px;
    height: 167px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.statsCard:hover {
    margin-left: 40px;
}

.statsFirst {
    color: #20184E;
    text-align: center;
    font-family: Nunito;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.statsSecond {
    color: #ADABB7;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.statsHeading {
    color: #20184e;
    text-align: center;
    font-family: Nunito;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: capitalize;
}
.volume-text{
    color: #ADABB7;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
.centerGrid {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 768px) and (max-width: 991px) {

    /* Your CSS styles for MD screens go here */
    .statsHeading {
        font-size: 38px;
        max-width: 619px;
    }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {

    /* Your CSS styles for SM screens go here */
    .statsHeading {
        font-size: 38px;
        max-width: 619px;
    }
}

@media screen and (max-width: 470px) {
    .statsHeading {
        font-size: 35px;
    }
}
</style>
