<template>
  <div>
    <div class="bg-theme">
      <CommonHeader />
      <div>
        <v-row>
          <v-col cols="12" lg="6" class="d-flex justify-center align-center">
            <div class="policyHolder">
              <h1 class="aboutSectionHeading ml-13">our Privay Policy</h1>
              <v-divider class="dividerNewsCard mt-2 mb-4 ml-13"></v-divider>
            </div>
          </v-col>
          <!-- <v-col cols="12" lg="6">
            <div class="position-relative">
              <v-img
                v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 500,
                  },
                }"
                :src="about"
                max-height="650"
              />
              <v-img
                max-width="400px"
                min-width="399px"
                class="floating-img"
                :src="blue"
              />
            </div>
          </v-col> -->
        </v-row>
      </div>
    </div>
    <div style="padding: 10% 5% 2%" class="bg-white-theme">
      <v-divider class="dividerNewsCard mb-4"></v-divider>
      <h1 class="aboutPlatforms">
        Our Policy: Clear, Comprehensive, and Built for You
      </h1>
      <p class="mb-5 mt-3 aboutPlatformsText">
        At MTS, we believe in keeping things simple, effective, and affordable.
        Our platform is designed to ensure your experience is smooth,
        hassle-free, and focused on what truly matters: your time.<br />
      </p>
      <p class="mb-5 mt-3 aboutPlatformsText">
        MTS’s policy is centered on providing transparent, ethical, and
        user-focused services, combining cutting-edge A.I. with accurate data
        collection to streamline Medicare & Medicaid reimbursements. We’re
        committed to respecting your privacy and protecting your data, while
        ensuring you have the tools to achieve maximum efficiency with minimal
        effort.<br />
      </p>
      <p class="aboutPlatformsText mb-16">
        In a world where healthcare professionals are increasingly burdened with
        time-consuming administrative tasks, our goal is to provide a solution
        that delivers both value and flexibility. Whether you choose to stick
        with traditional methods or use a complex, overpriced tool, MTS ensures
        you never have to compromise on performance or budget. Our policy
        guarantees that you can optimize your workflow, stay compliant with
        industry standards, and focus on patient care—all without breaking the
        bank. With MTS, you can rest assured that your time and resources are
        valued, and that we’ll always prioritize your success.
      </p>
      <p class="aboutPlatformsText mb-16">
        This is MTS: Policy that works for you.
      </p>
    </div>
    <FooterCom />
  </div>
</template>
<script>
import CommonHeader from "../components/CommonNavbar";
import blue from "../assets/images/blue.png";
import FooterCom from "@/components/FooterCom";
export default {
  components: {
    CommonHeader,
    FooterCom,
  },
  data() {
    return {
      blue: blue,
    };
  },
};
</script>
<style>
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
</style>
<style scoped>
.bg-white-theme {
  background: #f2f4f6;
}
.aboutPlatforms {
  color: #002a52;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 700px;
  font-weight: 700;
  line-height: 50px;
}
.aboutPlatformsText {
  color: #002a52;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.5;
}
.policyHolder {
  padding: 0 2%;
  margin-bottom: 15%;
  margin-top: 15%;
}
.aboutSectionHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.aboutSectionParagraph {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  max-width: 570px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  opacity: 0.7;
}
.bg-theme {
  background-color: #002a52;
  background-image: url(../assets//images/bg-circle.png);
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-height: 9px;
}
.floating-img {
  position: absolute;
  bottom: -100px;
  right: 10%;
}
@media (max-width: 991px) {
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
  .aboutPlatformsText {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
}
@media screen and (max-width: 470px) {
  .aboutSectionHeading {
    font-size: 35px;
    text-align: center;
  }
  .aboutPlatforms {
    font-size: 35px;
    line-height: 40px;
  }
  .aboutSectionParagraph {
    padding-left: 0 !important;
    text-align: center;
  }
  .dividerNewsCard {
    margin: auto;
  }
}
</style>
