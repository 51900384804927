<template>
  <div class="bg-white-theme pa-16 ">
    <v-card class="aboutCard pt-16 pb-16 pl-16 d-flex flex-row">
     <v-col cols="12" md="6" >
      <v-divider class="dividerCard"></v-divider>
      <p class="aboutUs mb-3">Time Study A.I. </p>
      <p class="aboutUs mb-3">Assistant</p>
      <p class="aboutTxt mb-5 pl-5">
       Your own personalized A.I. assistant allows you
        to easily record your time with voice. You can
      manage your time hours on-the-go with our customized
      speech recognition technology. Secure and scalable
      platform lets you perform any task without a desktop
      . Auto-reminders help you stay on top of your pending 
      tasks. Get better results with MTS.
      </p>
      <v-btn class="ExploreMore my-3" href="/about">Explore More</v-btn>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end align-center background-hand">
    <v-img class="v-img-animation" src="../assets/images/ai-mob.png"></v-img>
      <!-- <v-img  v-motion
        :initial="{
          y: 100,
        }"
        :visible="{
          y: 0,

          transition: {
            duration: 500,
          },
        }"
       class="AItimeStudy"
      :src="cardImage"
      alt="AI Time Study"></v-img> -->
    </v-col>
    </v-card>
  </div>
</template> 
<script>
import cardImage from "../assets/images/mobileAihands.png";

export default {
  data() {
    return {
      cardImage: cardImage,
    };
  },
};
</script>

<style scoped>
@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}

.v-img-animation {
  height: 85%;
  position: relative;
  bottom: 50px;
  animation: moveUpDown 3s infinite;
}
.background-hand{
  background-image: url('../assets/images/mobileAihands.png');
     background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.AItimeSTudy{
  width: 130%;
  max-height: 600px;
  object-fit: cover;
  
}
.dividerCard {

     border-radius: 57px;
    border: 0;
    background: #18afba;
    opacity: 1;
    max-width: 44px;
    min-height: 9px;
    margin: 34px 0px;
}
.aboutCard {
  background-color: #002A52;
  border-radius: 30px;
  background-repeat: no-repeat;
  margin: auto;
}
.bg-white-theme {
  background: #f2f4f6;
}
.aboutUs {
  color: #fff;
  text-align: left;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.aboutTxt {
  color: #ededed;
  text-align: left;
  font-family: Nunito;
  padding: 20px 33px 0px 0px;
  font-size: 20px;
  max-width: 750px;
  margin: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.ExploreMore {
  border-radius: 40px;
  background: #18afba;
  color: #fff;
  text-align: start;
  font-family: Nunito;
  font-size: 18px;
  text-transform: capitalize;
  font-style: normal;
  min-height: 57px;
  width: 173px;
  margin: 40px 20px !important;
  font-weight: 500;
  margin: auto;
  line-height: normal;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 470px) {
  .aboutUs {
    font-size: 35px;
  }
  .aboutTxt {
    font-size: 18px;
  }
}
/* @keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
} */

.AItimeStudy {
  animation: moveUpDown 2s infinite;
}
</style>
