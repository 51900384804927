<template>
  <div>
    <div class="bg-theme">
      <CommonHeader />
      <div>
        <v-row>
          <v-col cols="12" lg="6" class="d-flex justify-center align-center">
            <div class="Holder">
              <h1 class="aboutSectionHeading">
                Subscription-based Pricing That Eliminates Risk
              </h1>
              <v-divider class="dividerNewsCard mt-2 mb-4"></v-divider>
              <div>
                <p class="aboutSectionParagraph ps-16">
                  Transparent Pricing Accommodates Your Team’s Growth
                </p>
              </div>
            </div>
          </v-col>
          <v-col style="padding: 0" cols="12" lg="6">
            <div class="position-relative">
              <v-img
                v-motion
                :initial="{
                  x: 100,
                  opacity: 0,
                }"
                :visible="{
                  x: 0,
                  opacity: 1,
                  transition: {
                    duration: 500,
                  },
                }"
                max-height="400px"
                :src="pricing"
              />
              <v-img
                max-width="400px"
                min-width="399px"
                class="floating-img"
                :src="blue"
              />
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div style="padding: 8% 5%" class="feature-bg">
      <v-divider class="dividerNewsCard mb-4"></v-divider>
      <h1 class="aboutPlatforms">
        The Most Trusted Time Sheet and Reporting Solution
      </h1>
      <p class="mb-5 mt-3 aboutPlatformsText">
        Time Sheet is a secure, enterprise-class platform built for healthcare
        teams in need of a modern, comprehensive, and intuitive way to manage
        time capture and reporting processes. Our automated alerts and real-time
        reporting maximize compliance, reduce administrative time by staff, and
        provide a rock-solid audit trail.
      </p>
    </div>
  </div>
</template>
<script>
import CommonHeader from "../components/CommonNavbar";
import about from "../assets/images/aboutImage.png";
import blue from "../assets/images/blue.png";
import aboutMain from "../assets/images/start-up.png";
import pricing from "../assets/images/pharama-doc-2.png";
export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      about: about,
      blue: blue,
      aboutMain: aboutMain,
      pricing: pricing,
    };
  },
};
</script>
<style scoped>
.bg-white-theme {
  background: #f2f4f6;
}

.aboutPlatforms {
  color: #002a52;
  font-family: Nunito;
  font-size: 48px;
  font-style: normal;
  max-width: 700px;
  font-weight: 700;
  line-height: 50px;
}
.aboutPlatformsText {
  color: #002a52;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  opacity: 0.5;
}
.aboutSectionHeading {
  color: #fff;
  font-family: Nunito;
  font-size: 48px;
  max-width: 500px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: capitalize;
}
.aboutSectionParagraph {
  color: #fff;
  font-family: Nunito;
  font-size: 20px;
  max-width: 570px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  opacity: 0.7;
}
.bg-theme {
  background-color: #002a52;
  background-image: url(../assets//images/bg-circle.png);
}
.feature-bg {
  background-image: url(../assets/images/half.png);
  background-position: right;
  background-position: right top;
}
.dividerNewsCard {
  border-radius: 57px;
  border: 0;
  background: #18afba;
  opacity: 1;
  max-width: 44px;
  min-height: 9px;
}
.floating-img {
  position: absolute;
  bottom: -100px;
  right: 10%;
}
@media (max-width: 991px) {
  /* Your CSS styles for MD screens go here */
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
  .aboutPlatformsText {
    font-size: 18px;
  }
}

/* For small screens (SM) */
@media screen and (max-width: 767px) {
  /* Your CSS styles for SM screens go here */
  .aboutPlatforms {
    font-size: 38px;
    max-width: 619px;
  }
}
.Holder {
  padding: 0 2%;
}
@media screen and (max-width: 470px) {
  .aboutSectionHeading {
    font-size: 35px;
    text-align: center;
  }
  .aboutSectionParagraph {
    text-align: center;
    padding-left: 0 !important;
  }
  .dividerNewsCard {
    margin: auto;
  }
}
</style>
