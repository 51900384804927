<template>
<div>
    <div style="padding: 8% 5%" class="bg-theme">
        <v-row>
            <v-col cols="12" lg="5" md="12" class="d-flex justify-center align-center">
                <div>
                    <v-divider class="dividerNewsCard mb-4"></v-divider>
                    <h1 class="joinHeading mb-3">Why Us!</h1>
                    <p class="joinText ml-8 mb-3">
                        Why should you choose Medicare Time Study?<br><br> We’re currently growing our teams. The following roles are currently available or opening soon.
                    </p>
                    <v-btn class="joinBtn ml-8">Schedule a Demo</v-btn>
                </div>
            </v-col>
            <v-col cols="12" lg="7" md="12">
                <div>
                    <v-row>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <div v-motion :initial="{
                    scale: 0,
                  }" :visible="{
                    scale: 1,
                    transition: {
                      duration: 300,
                    },
                  }" class="joinCard">
                                <p class="joinCardHeading">Machine Learning and<br>
                                    A.I. Powered</p>
                                <v-divider class="dividerNewsCard smallDivider mb-4"></v-divider>
                                <p class="joinCardText">
                                    We put intelligence ahead in every<br> feature of our platform. Manage<br> your time smartly with MTS.
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <div v-motion :initial="{
                    scale: 0,
                  }" :visible="{
                    scale: 1,
                    transition: {
                      duration: 300,
                      delay: 100,
                    },
                  }" class="joinCard">
                                <p class="joinCardHeading">Budget Friendly</p>
                                <v-divider class="dividerNewsCard smallDivider mb-4"></v-divider>
                                <p class="joinCardText">
                                    Get all the tools you need to<br> enhance productivity and <br>efficiency, at a price that suits<br> your budget.
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class='row-margin'>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <div v-motion :initial="{
                    scale: 0,
                  }" :visible="{
                    scale: 1,
                    transition: {
                      duration: 300,
                      delay: 200,
                    },
                  }" class="joinCard">
                                <p class="joinCardHeading">Better Productivity</p>
                                <v-divider class="dividerNewsCard smallDivider mb-4"></v-divider>
                                <p class="joinCardText">
                                    Enhance your productivity By <br>prioritizing tasks, minimizing<br>distractions, and utilizing<br> productivity software.
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                            <div v-motion :initial="{
                    scale: 0,
                  }" :visible="{
                    scale: 1,
                    transition: {
                      duration: 300,
                      delay: 300,
                    },
                  }" class="joinCard">
                                <p class="joinCardHeading">More Reimbursements</p>
                                <v-divider class="dividerNewsCard smallDivider mb-4"></v-divider>
                                <p class="joinCardText">
                                    Utilizing efficient tracking and <br>reporting tools ensures you <br> capture all eligible expenses, <br> reducing errors and delays.
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

    </div>
</div>
<div style="background-color: #f2f2f2	">
    <v-row>
        <v-col>
            <div class="d-flex justify-center mb-4 mt-10">
                <v-divider class="dividerNewsCard smallDivider"></v-divider>
            </div>
            <p class="text-center believe-text">We believe in empowering you through our <br>web and mobile platforms to get maximum  <br>productivity and reimbursements.</p>
            <div class="d-flex justify-center mb-4">
                <v-btn class="getStarted" href="https://dashboard.medicaretimestudy.com/">Schedule A Demo</v-btn>

            </div>
        </v-col>
    </v-row>
</div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style scoped>
.smallDivider {
    max-width: 29px !important;
    min-height: 6px !important;
}

.joinCard {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    /* width: 345px; */
    min-height: 209px;
}

.joinCardHeading {
    color: #fff;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.joinCardText {
    color: #adabb7;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    /* 23.4px */
}

.joinHeading {
    color: #fff;
    font-family: Nunito;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: capitalize;
}

.joinText {
    color: #fff;
    font-family: Nunito;
    font-size: 20px;
    max-width: 335px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    opacity: 0.7;
}

.bg-theme {
    background-color: #002a52;
    background-image: url(../assets//images/bg-circle.png);
    background-position: left top;
    background-size: contain;
}

.dividerNewsCard {
    border-radius: 57px;
    border: 0;
    background: #18afba;
    opacity: 1;
    max-width: 44px;
    min-height: 9px;
}

.joinBtn {
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 40px;
    background: #18afba;
    text-transform: capitalize;
    min-height: 55px;
    box-shadow: none;
}
.believe-text{
  color: #20184E;
    text-align: center;
    font-family: Nunito;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 57px;
}

.row-margin {
    margin-left: 2%
}

@media screen and (max-width: 470px) {
    .joinHeading {
        font-size: 35px;
    }

    .row-margin {
        margin-left: 0 !important;
    }
}


@media screen and (max-width: 600px) {
  .believe-text{
  color: #20184E;
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 37px;
}
}

.getStarted {
    border-radius: 40px;
    background: #18afba;
    color: #fff;
    text-align: center;
    font-family: Gowun Dodum;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-height: 48px;
    width: 190px;
    box-shadow: none;
    text-transform: capitalize;
}
</style>
