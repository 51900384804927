<template>
<div class="full-bg">
    <div class="pt-16 feature-bg">
        <v-divider class="dividerNewsCard mt-16 mb-4"></v-divider>
        <h1 class="teamHeading">Transplant Centers Transform <br>with Modernized Processes</h1>
        <p class="featuresPara mb-16 mt-2">
            Transplant Centers are witnessing transformative results by modernizing their <br>processes. These advancements are not only bolstering the bottom line but also<br> streamlining the finance team’s workload, making annual Cost Reports more audit-<br>ready, and lightening the clinical staff’s load. </p>
    </div>
    <div class="position-relative mt-16">
        <div v-motion :initial="{
          y: 100,
        }" :visible="{
          y: 0,

          transition: {
            duration: 500,
          },
        }">
            <v-img class="laptopMockup floating-img" :src="smart" />
        </div>
        <div class="bg-theme mainWeb min-height-600">
            <div class="d-flex justify-center pb-8">
                <router-link to="/contact">
                    <v-btn class="signUp mb-16">Request a Demo</v-btn>
                </router-link>
            </div>
        </div>

    </div>
</div>
<div class="pt-16">
    <v-divider class="dividerNewsCard mt-16 mb-4"></v-divider>
    <h1 class="teamHeading">The #1 Choice of the Leading<br>Transplant Centers</h1>
    <p class="featuresPara mb-16 mt-2">
        Time Study counts among our client list one-quarter of the Top 40 Transplant<br> Centers. In total, we have studied more than 13 million hours at more than 75<br> hospitals serving more than 90,000 licensed users. </p>
</div>
<div class="cards-bg">
    <v-container>
        <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12">
                <div>
                    <v-row class="d-flex flex-wrap align-stretch">
                        <v-col cols="12" md="6" lg="4" v-for="(slide, index) in carouselSlides" :key="index">
                            <div class="organization-card d-flex flex-colum text-center">
                                <h2 class="orgCardHeading">{{ slide.heading }}</h2>
                                <p class="orgCardText">{{ slide.text }}</p>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-container>
</div>
<div style="padding: 10% 5% 2%" class="bg-white-theme">
    <v-row>

        <v-col cols="12" md="6">
            <v-divider class="dividerNewsCard mb-4"></v-divider>
            <h1 class="aboutPlatforms">
                Streamline Task<br> Management with the <br>Faculty Module </h1>
            <p class="mb-5 mt-10 aboutPlatformsText ml-5">
                The Faculty Module in the hospital dashboard<br> empowers clinical staff to efficiently manage their tasks<br> and schedules. Designed specifically for faculty<br> members, this module allows users to log their time and<br> activities according to predefined timelines, ensuring<br> accurate tracking and reporting. By simplifying task<br> management, the Faculty Module enhances<br> productivity, enabling clinical staff to focus more on<br> delivering exceptional patient care.
            </p>
        </v-col>
        <v-col cols="12" md="6">
            <v-img :src="MocRates" />
        </v-col>
    </v-row>

</div>
<div class="full-bg">
    <div class="pt-16 feature-bg">
        <v-divider class="dividerNewsCard mt-16 mb-4"></v-divider>
        <h1 class="teamHeading">Optimize Clinical & Non-<br>Clinical Task Management</h1>
        <p class="featuresPara mb-16 mt-2">
            Utilize our software's Faculty Module to streamline and optimize your task management.<br> Specifically designed for hospital faculty, this feature allows you to easily enter and track your time <br>according to the provided timelines. Enhance your efficiency, ensure precise time logging, and<br> reduce administrative burdens, enabling you to focus more on delivering high-quality clinical<br> services. Experience the benefits of our intuitive and reliable Faculty Module today.</p>
    </div>
    <div class="position-relative mt-16">
        <div v-motion :initial="{
          y: 100,
        }" :visible="{
          y: 0,

          transition: {
            duration: 500,
          },
        }">
            <v-img class="laptopMockup floating-img" :src="smartSec" />
        </div>
        <div class="bg-theme mainWeb min-height-600">
            <div class="d-flex justify-center pb-8">
                <router-link to="/contact">
                    <v-btn class="signUp mb-16">Request a Demo</v-btn>
                </router-link>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import smart from "../assets/images/product-app.png";
import MocRates from "../assets/images/streamline-doc.png";
import smartSec from "../assets/images/mac.png";

export default {
    data() {
        return {
            smart: smart,
            MocRates: MocRates,
            smartSec: smartSec,
            carouselSlides: [{
                    heading: ">3 Mo.",
                    text: "Payback Period",
                },
                {
                    heading: "+128%",
                    text: "Participation",
                },
                {
                    heading: "+130%",
                    text: "Submissions",
                },
                {
                    heading: "+80%",
                    text: "Time-to-capture",
                },
                {
                    heading: "+40%",
                    text: "Total Hrs. Reported",
                },
                {
                    heading: "+56%",
                    text: "Reimbursable Hrs.",
                },

            ],

        };
    },
};
</script>

<style scoped>
.laptopMockup {
    /* position: absolute;
    left: 0;
    right: 0;
    min-width: 80vw; */
}

.mainWeb {
    background-image: url(../assets//images/bg-circle.png);
    background-size: contain;
}

.laptopMockup {
    margin-bottom: -33% !important;
    width: 80vw;
    margin: auto;
}

.min-height-600 {
    min-height: 600px;
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: center;
}

.signUp {
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 40px;
    background: #18afba;
    text-transform: capitalize;
    box-shadow: none;
    height: 57px;
    margin: auto;
    width: 192px;
}

.bg-theme {
    background-color: #002a52;
}

.smallDivider {
    min-width: 29px !important;
    min-height: 6px !important;
}

.full-bg {
    background-color: #002a520b;
}

.feature-bg {
    background-image: url(../assets/images/half.png);
    background-position: right;
    background-position: right top;
}

full-bg-two {
    background-color: white !important;
}

.dividerNewsCard {
    border-radius: 57px;
    border: 0;
    background: #18afba;
    opacity: 1;
    max-width: 44px;
    margin: auto;
    min-width: 44px;
    min-height: 9px;
}

.teamHeading {
    color: #002a52;
    text-align: center;
    font-family: Nunito;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
}

.featuresPara {
    color: #002a5286;
    text-align: center;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.organization-card {
    border-radius: 20px;
    background: #fff;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 48px 37px 104px 0px #140b4817;
    border: 1px solid #00000033;

}

.cards-bg {
    background-image: url(@/assets//images/choicebg.png);
    background-size: contain;
    padding: 100px;
}

.orgCardHeading {
    color: #20184E;
    font-family: Nunito;
    padding-top: 20px;
    font-size: 30px;
    font-weight: 700;
}

.orgCardText {
    color: #adabb7;

    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    flex-grow: 1;
}

.bg-white-theme {
    background: #FFFFFF;
}

.aboutPlatforms {
    color: #002a52;
    font-family: Nunito;
    font-size: 48px;
    font-style: normal;
    max-width: 700px;
    font-weight: 700;
    line-height: 50px;
}

.aboutPlatformsText {
    color: #002a52;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    opacity: 0.5;
}

.AboutHolder {
    padding: 0 2%
}

.aboutSectionHeading {
    color: #fff;
    font-family: Nunito;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: capitalize;
}

.aboutSectionParagraph {
    color: #fff;
    font-family: Nunito;
    font-size: 20px;
    max-width: 570px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 160% */
    opacity: 0.7;
}
@media (max-width: 600px) {
    .cards-bg {
    padding: 20px;
  }
 
}
</style>
