<template>
  <div>
    <BlogsHeader />
    <FooterCom />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import BlogsHeader from "@/components/BlogsHeader";
import FooterCom from "@/components/FooterCom";

export default defineComponent({
  name: "PricingView",

  components: {
    BlogsHeader,
    FooterCom,
  },
});
</script>
