<template>
  <div>
      <div class="bg-theme">
          <CommonHeader />
          <div>
              <v-row>
                  <v-col cols="12" lg="6" class="d-flex justify-center align-center">
                      <div class="AboutHolder">
                          <h1 class="aboutSectionHeading ml-13">Revolutionizes Transplant<br> Centers & Faculties</h1>
                          <v-divider class="dividerNewsCard mt-2 mb-4 ml-13"></v-divider>
                          <div>
                              <p class="aboutSectionParagraph ps-16 mt-10">
                                Taking control of the most complex time <br>studies in healthcare with our innovative and<br> proven software platform
                              </p>
                          </div>
                      </div>
                  </v-col>
                  <v-col cols="12" lg="6">
                      <div class="position-relative">
                          <v-img v-motion :initial="{
                      x: 100,
                      opacity: 0,
                    }" :visible="{
                      x: 0,
                      opacity: 1,
                      transition: {
                        duration: 500,
                      },
                    }" :src="moc" max-height="650" />
                          <v-img max-width="400px" min-width="399px" class="floating-img" :src="blue" />
                      </div>
                  </v-col>
              </v-row>
          </div>
      </div>
      <div style="padding: 10% 5% 2%" class="bg-white-theme">
          <v-row>
              <v-col cols="12" md="6">
                <v-img :src="MocRates" />
              </v-col>
              <v-col cols="12"  md="6">
                <v-divider class="dividerNewsCard mb-4"></v-divider>
          <h1 class="aboutPlatforms">
            Our New Transplant Center Initiative Package Helps Drive Innovation </h1>
            <p class="mb-5 mt-10 aboutPlatformsText">
              Our experience, built over years of transforming time studies in<br> Transplant Centers, allows us to tailor a package specific to<br> your needs. The flat-rate, all-inclusive offer includes user<br> licenses, mobile app, full reporting, implementation, training,<br> and ongoing support.<br><br>
We can get your team up and running quickly (12-16 weeks is<br> typical) with a single “all in” price that is very reasonable. The<br> ROI case is compelling, with your peers experiencing 10-15X <br>returns in the first year.
          </p>
          <v-btn class="getStarted mt-10" :to="'/contact'">Schedule a Demo</v-btn>

              </v-col>
          </v-row>
         
      </div>
  </div>
  </template>
  
    
  <script>
  import CommonHeader from "../components/CommonNavbar";
  import moc from "../assets/images/Products-moc.png";
  import blue from "../assets/images/blue.png";
  import MocRates from "../assets/images/Products-building.png";
  export default {
      components: {
          CommonHeader,
      },
      data() {
          return {
              moc: moc,
              blue: blue,
              MocRates: MocRates,
          };
      },
  };
  </script>
    
  <style>
  .floating-img {
      animation-name: floatAnimation;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;
  }
  
  @keyframes floatAnimation {
      0% {
          transform: translateY(0);
      }
  
      100% {
          transform: translateY(-20px);
      }
  }
  </style><style scoped>
  .bg-white-theme {
      background: #FFFFFF;
  }
  
  .aboutPlatforms {
      color: #002a52;
      font-family: Nunito;
      font-size: 48px;
      font-style: normal;
      max-width: 700px;
      font-weight: 700;
      line-height: 50px;
  }
  
  .aboutPlatformsText {
      color: #002a52;
      font-family: Nunito;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      opacity: 0.5;
  }
  
  .AboutHolder {
      padding: 0 2%
  }
  
  .aboutSectionHeading {
      color: #fff;
      font-family: Nunito;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      text-transform: capitalize;
  }
  
  .aboutSectionParagraph {
      color: #fff;
      font-family: Nunito;
      font-size: 20px;
      max-width: 570px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      /* 160% */
      opacity: 0.7;
  }
  
  .bg-theme {
      background-color: #002a52;
      background-image: url(../assets//images/bg-circle.png);
  }
  
  .dividerNewsCard {
      border-radius: 57px;
      border: 0;
      background: #18afba;
      opacity: 1;
      max-width: 44px;
      min-height: 9px;
  }
  
  .floating-img {
      position: absolute;
      bottom: -100px;
      right: 10%;
  }
  
  @media (max-width: 991px) {
  
      .aboutPlatforms {
          font-size: 38px;
          max-width: 619px;
      }
  
      .aboutPlatformsText {
          font-size: 18px;
      }
  }
  
  @media screen and (max-width: 767px) {
  
      .aboutPlatforms {
          font-size: 38px;
          max-width: 619px;
      }
  }
  
  @media screen and (max-width: 470px) {
      .aboutSectionHeading {
          font-size: 35px;
          text-align: center
      }
  
      .aboutPlatforms {
          font-size: 35px;
          line-height: 40px;
      }
  
      .aboutSectionParagraph {
          padding-left: 0 !important;
          text-align: center
      }
  
      .dividerNewsCard {
          margin: auto;
      }
  }
  
  .getStarted {
    border-radius: 40px;
    background: #18afba;
    color: #fff;
    text-align: center;
    font-family: Gowun Dodum;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-height: 48px;
    width: 190px;
    box-shadow: none;
    text-transform: capitalize;

  }
  </style>
  